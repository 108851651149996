@import '@assets/scss/v2/main';

.container {
  align-items: center;
  height: 3.3rem;
  display: flex;
}

.label {
  margin: auto 0;
}

.icon {
  height: 3.3rem;
  width: auto;
}

.text {
  @include textSmall;

  margin-left: 0.8rem;
  display: inline-flex;
  align-items: center;
}

.infoIcon {
  height: 12px;
  width: auto;
  margin-left: 5px;
}

.link {
  cursor: pointer;
  margin-top: auto;
}

.basicPosition {
  display: flex;
}

.productCardPosition {
  position: absolute;
}
