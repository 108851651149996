@import '@assets/scss/v2/main';

.container {
  @include respond(m) {
    margin-bottom: 1.6rem;
  }
}

.paddingContent {
  padding: 2.4rem;

  @include respond(m) {
    padding: 4rem 8rem;
  }
}

.centerContentBlock {
  @include respond(m) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.maxWidth {
  max-width: 84rem;

  @include respond(m) {
    width: 84rem;
    &.contentSmaller {
      width:100%;
    }
  }
}

.headerRegular {
  margin-bottom: 1.2rem;
  @include line-height-normal;
  @include text-2xl;
  @include font-weight-bold;
}

.headerBigger {
  margin-bottom: 1.2rem;
  @include line-height-thight;
  @include text-4xl;
  @include font-weight-medium;
}

.headerSmaller {
  margin-bottom: 1.2rem;
  @include font-sans-bold;
  @include line-height-normal;
  @include text-base;
  @include font-weight-bold;
}

.contentRegular {
  @include line-height-relaxed;
  @include text-lg;
  @include font-weight-normal;
}

.contentBigger {
  @include line-height-relaxed;
  @include text-base;
  @include font-weight-normal;
}

.contentSmaller {
  @include line-height-normal;
  @include text-base;
  @include font-weight-normal;
}

.themeBackgroundWhite {
  background-color: $white;
}

.themeBackgroundBlueBright {
  background-color: $blue-bright;
}

.themeBackgroundBlueDark {
  background-color: $blue-dark;
}

.themeBackgroundBlueLight {
  background-color: $blue-light;
}

.themeBackgroundBrandYellow {
  background-color: $brand-yellow;
}

.themeBackgroundBrandPink {
  background-color: $brand-pink;
}

.themeBackgroundBrandGreen {
  background-color: $brand-green;
}

.themeBackgroundBlueMuted {
  background-color: $blue-solitude;
}

.themeBackgroundTransparent {
  background-color: transparent;
}

.themeColorWhite {
  color: $white;
}

.themeColorBlack {
  color: $black;
}
