@import '@assets/scss/v2/main';

.container {
  margin-top: 3rem;
}

.title {
  margin-bottom: 3rem;
  color: $blue-bright !important;

  @include textExtraLargeBold;
}
