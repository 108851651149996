.wrapper {
  position: relative;
}

.showOnTop {
  bottom: 100%;
}

.showOnBottom {
  top: 100%;
}

.showOnLeft {
  right: 0;
}

.showOnRight {
  left: 0;
}