@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;

  @include respond(xl) {
    width: 60rem;
  }
}

.question {
  @include textDoubleExtraLargeBold;

  color: $black;
  margin-bottom: 1.2rem;
}

.answerContainer {
  max-height: 10rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: wrap;
}

.answer {
  @include text;

  color: $grey-generic;
}

.readMore {
  @include text;

  width: fit-content;
  color: $grey-generic;
  text-decoration: underline;
  padding-top: 1rem;
}
