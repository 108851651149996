@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 2rem 2rem;

  @include respond(xl) {
    padding: 2rem 0;
  }

  @include respond(xxl) {
    margin-right: 2rem;
    padding: 0.5rem 0 2rem;
  }
}

.title {
  line-height: 2.64rem;
  font-size: 2.2rem;

  @include font-weight-bold;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.inputRow {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  margin: 1rem 0;
}

.singleInput {
  display: flex;
  flex-direction: column;
  width: 100%;
}
