@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.imageContainer {
  max-width: 5.1rem;
  max-height: 5.1rem;
  margin-right: 0.5rem;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 0.6rem;
  width: 100%;
  color: $black;
}

.deliverySection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.greenDot {
  height: 7px;
  width: 7px;
  border-radius: 50px;
  background-color: $brand-green;
  margin-right: 0.8rem;
}

.editSection {
  margin-left: 3.3rem;
}

.editText {
  @include textExtraSmallBold;

  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
  color: $black;
}

.headerTitle {
  @include textBold;
}

.headerText {
  @include textBold;
}

.subHeader {
  @include textSmall;
}

.deliveryTimeText {
  @include textSmall;
}
