@import '@assets/scss/v2/main';

.container {
  margin-top: 3.2rem;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10rem;
}

.separator {
  margin-top: 0.8rem;
}

.subscriptionCardContainer {
  margin-bottom: 1.6rem;
}

.selected {
  outline: 3px solid $blue-bright;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.errorMessage {
  @include textMedium;

  display: flex;
  flex-direction: row;
  margin: 0.4rem 0 0 1.6rem;
  color: $error;
}

.rectangleIcon {
  margin-right: 0.4rem;
}
