.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  align-items: center;
}

.leftColumn {
  grid-column: 1 / 2;
}

.rightColumn {
  grid-column: 2 / 3;
  justify-self: end;
}
