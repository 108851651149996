@import '@assets/scss/v2/main';

.cardsWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  position: relative;
  top: -2.5rem;

  @include respond(l) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  @include respond(xl) {
    height: 0;
    top: -10rem;
  }
}

.imageContainer {
  width: 100%;
  height: 28rem;
  position: relative;

  @include respond(m) {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 48rem;
    background-color: $blue-bright;
  }
}

.image {
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 60% 0;
    @include respond(m) {
      object-fit: none;
      object-position: 50% 50%;
    }
  }
}

.headerWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;
}

.headerTitle {
  margin: 4rem 1rem 1rem;
  text-align: center;
  @include text-4xl;
  @include font-weight-medium;
  @include line-height-thight;

  @include respond(m) {
    margin: 11rem 0 1.6rem;
    max-width: 72rem;
    @include text-5xl;
  }
}

.headerContent {
  margin: 1rem;
  @include font-weight-normal;
  @include line-height-relaxed;
  text-align: center;

  @include respond(m) {
    margin: 0 0 1.6rem;
    max-width: 72rem;
    @include text-lg;
  }
}
