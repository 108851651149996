@import '@assets/scss/v2/main';

.title {
    @include textBold;
    @include line-height-thight;
}

.responsive {
    @include respond(m) {
        @include textQuadrupleExtraLargeMediumBold;
        @include line-height-normal;
    }
}
