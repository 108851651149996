@import '@assets/scss/v2/main';

.datasheetLinkEnergyLabel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.datasheetLinkEnergyLabelText {
    @include textMedium;
    @include line-height-thight;
}