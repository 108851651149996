@import '../../../../assets/scss/shared/breakpoints';

.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.3);
}

.wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-x: hidden;
  overflow-y: auto;
  width: 90vw;

  @include respond(sm) {
    width: 500px;
  }
}

.largeModal {
  height: 100%;
  width: 100%;
  overflow-y: hidden;

  @include respond(md) {
    height: 90%;
    width: 750px;
    overflow-y: hidden;
  }
}

@keyframes fadeIn {
  0% {
    transform: translate(-50%, 0);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.fadeIn {
  animation: 0.3s fadeIn;
  -moz-animation: 0.3s fadeIn;
  -webkit-animation: 0.3s fadeIn;
  -o-animation: 0.3s fadeIn;
  transition-delay: 200ms;
}

.body {
  background: #fff;
  overflow: hidden;
  height: 100%;
}

.content {
  overflow-y: auto;
  height: calc(100% - 7rem);
  padding: 2rem;
  margin-top: 0.2rem;
}

.content.withFooter {
  height: calc(100% - 17rem);
}