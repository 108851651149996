@import '@assets/scss/v2/main';

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: $white;
  gap: $spacing-s;
  height: 100%;
  min-width: 33.5rem;
  max-width: 63rem;
  padding: $spacing-m;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: $spacing-xs;

  @include respond(xs) {
    min-width: 33.5rem;
    max-width: 33.5rem;
  }

  @include respond(s) {
    min-width: 33.5rem;
    max-width: 33.5rem;
  }

  @include respond(m) {
    min-width: 59rem;
    max-width: 59rem;
  }
}

.topSection {
  display: flex;
  gap: $spacing-s;
}

.topRow {
  display: flex;
  gap: $spacing-l;
}

.productVib {
  @include text;
}

.labelText {
  @include textExtraSmallBold;
  @include line-height-none;
}

.productName {
  @include textDoubleExtraLargeBold;
  @include line-height-normal;
}

.image {
  max-width: 13rem;
  max-height: 10.9rem;

  @include respond(xs) {
    max-width: 10rem;
    max-height: 18rem;
  }

  @include respond(s) {
    max-width: 10rem;
    max-height: 18rem;
  }

  @include respond(m) {
    max-width: 13rem;
    max-height: 10.9rem;
  }

  img {
    max-width: 13rem;
    max-height: 8rem;

    @include respond(xs) {
      max-width: 10rem;
      max-height: 8rem;
    }

    @include respond(s) {
      max-width: 10rem;
      max-height: 8rem;
    }

    @include respond(m) {
      max-width: 13rem;
      max-height: 8rem;
    }
  }
}

.defaultDelivery {
  display: flex;
  gap: $spacing-s;
}

.deliverySection {
  @include textSmall;
  opacity: 0.6;
}

.durationPriceSection {
  @include textSmallBold;
  @include line-height-none;
  background-color: $white-smoke;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  padding: $xs-col-gap;
}

.oneTimeFeeSection div {
  @include textSmall;
  opacity: 0.6;

  display: flex;
  flex-direction: row-reverse;
}
