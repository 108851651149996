@import '@assets/scss/v2/main';

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.deliveryContainer {
  @include textDoubleExtraSmall;
  margin-left: auto;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
