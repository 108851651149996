@import '@assets/scss/v2/main';

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fullScreen {
  position: fixed;
  background-color: $white;
  padding: 0 1rem;
  width: 100%;
  height: 100%;
  animation: fade 500ms;
  z-index: 1002;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.imageCounterContainer {
  position: fixed;
  top: 3.2rem;
  left: 3.2rem;
  z-index: 3;

  @include textLargeBold;
}

.closeButtonContainer {
  position: fixed;
  top: 3.2rem;
  right: 3.2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 3;

  @include respond(m) {
    justify-content: space-around;
  }
}

.closeButton {
  color: $black;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include textLargeBold;
}

.closeIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 1.7rem;

  >svg circle {
    display: none;
  }
}

.imageCarousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  @include respond(m) {
    height: auto;
    margin: 8.5rem;
  }
}

.topContainer {
  display: flex;
  align-items: center;
  align-self: center;
  width: 100%;
  justify-content: center;
  height: auto;

  @include respond(m) {
    height: 70vh;
  }
}

.arrowsContainer {
  display: none;

  @include respond(m) {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 3.2rem;
  }
}

.arrow {
  display: none;

  @include respond(m) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 5.8rem;
    height: 5.8rem;
    background: $white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    user-select: none;
    cursor: pointer;
    z-index: 3;
    margin: 0;
  }
}

.vectorIconRotated {
  transform: rotate(180deg);
}

.mainImage {
  transition: all 0.9s ease-in;
  position: relative;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  cursor: auto;
}

.image {
  display: flex;
  justify-content: center;
  padding: 2rem;
  align-items: center;

  img {
    display: block;
    width: 100% !important;
    height: auto !important;
  }

  @include respond(m) {
    max-width: 80vh;
    height: 68vh;
    padding: 0;
  }
}

.fullScreenNavigation {
  display: none;

  @include respond(m) {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    left: 0;
    right: 0;
    bottom: 0;
    height: 20vh;
    z-index: 3;

    .image {
      border: 1px solid $silver;
      border-radius: 4px;
      margin: 0.8rem;
      padding: 0.5rem;
      width: 7.2rem;
      height: 7.2rem;
      cursor: pointer;

      &.currentImage {
        border: 1px solid $black;
        border-radius: 4px;
      }
    }
  }
}