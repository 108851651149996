@import '@assets/scss/v2/main';

.gridContainerMyBm {
  display: grid;
  width: 100%;
  grid-template-columns: auto-fit auto-fit;
  grid-template-rows: min-content 2.1rem 5rem;
  grid-template-areas:
    'img title'
    'img subtitle'
    'img label';
  column-gap: 0.8rem;
  row-gap: 0.4rem;
  padding: 0.8rem 0 0.4rem 0;

  @include respond(m) {
    width: 36rem;
    grid-template-columns: auto-fit auto-fit;
    grid-template-rows: min-content 2.1rem 5rem;
    grid-template-areas:
      'img title'
      'img subtitle'
      'img label';
  }

  .img {
    justify-self: center;
    max-width: 6rem;
    max-height: 7.3rem;
    @include respond(m) {
      max-width: 8.3rem;
      max-height: 8.9rem;
    }
  }

  .label {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    flex-wrap: wrap;
  }
}

.gridContainerCheckout {
  width: 32rem;
  display: grid;
  grid-template-columns: max-content 20.8rem max-content;
  grid-template-rows: min-content 2.1rem 2.4rem;
  grid-template-areas:
    'img title bin'
    'img subtitle .'
    'label label label';
  column-gap: 0.8rem;
  row-gap: 0.4rem;
  padding: 0.8rem 0 0.4rem 0;

  @include respond(m) {
    width: 36rem;
    grid-template-columns: max-content 22.1rem max-content;
    grid-template-rows: min-content 2.1rem 2.4rem;
    grid-template-areas:
      'img title bin'
      'img subtitle .'
      'img label label';
  }

  .img {
    justify-self: center;
    max-width: 6rem;
    max-height: 7.3rem;

    @include respond(m) {
      max-width: 8.3rem;
      max-height: 8.9rem;
    }
  }

  .subtitle {
  }

  .bin {
    max-width: 2.4rem;
    max-height: 2.4rem;
  }

  .label {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
  }
}

.gridContainerPdp {
  width: 33.1rem;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-template-rows: repeat(5, max-content);
  grid-template-areas:
    'subtitle label label'
    'title . .'
    'text . .'
    'icon . .'
    'img . .';

  @include respond(m) {
    width: 100%;
    grid-template-columns: 50% max-content max-content max-content;
    grid-template-rows: repeat(4, auto) max-content;
    grid-template-areas:
      'img subtitle label label'
      'img title . .'
      'img text . .'
      'img icon . .'
      'img . . .';
  }

  .label {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
  }
}

.img {
  grid-area: img;
}

.title {
  grid-area: title;
}

.bin {
  grid-area: bin;
}

.subtitle {
  grid-area: subtitle;
}

.label {
  grid-area: label;
}

.label2 {
  grid-area: label2;
}

.text {
  grid-area: text;
}

.icon {
  grid-area: icon;
}
