@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
  justify-content: space-between;
}

.rowLine {
  border: none;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  height: 1px;
  background-color: $black;
}

.descriptionText {
  @include textLargeBold;
}

.summaryDescriptionText {
  @include textExtraLargeBold;
}

.valueText {
  @include textExtraLargeBold;
}
