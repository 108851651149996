@import '@assets/scss/v2/main';

.container {
  background-color: $blue-medium-bright;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.contentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: $blue-medium-bright;
  padding: 1.8rem 1.8rem 0;

  > * {
    color: $white;
  }
}

.backgroundContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 86.2rem;
  background-repeat: no-repeat;
  background-position: top;
}

.formContent {
  padding-top: 3.2rem;

  @media (min-width: 640px) {
    max-width: 640px;
  }

  @include respond(xs) {
    margin: 1.6rem 1.6rem 0;
  }

  @include respond(s) {
    margin: 2.4rem 2.4rem 0;
  }

  @include respond(m) {
    margin: 3.2rem 3.2rem 0;
  }
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10rem;
}
