@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 100%;
}

.topic {
  @include textQuadrupleExtraLargeMediumBold;

  color: $black;
  text-transform: capitalize;
}

.buttonContainer {
  display: flex;
  width: fit-content;
  margin: 2.8rem 0;
}

.notFound {
  @include textLarge;

  color: $black;
  display: flex;
  justify-content: center;
}

.question {
  @include textDoubleExtraLargeBold;

  color: $black;
  margin-bottom: 1.2rem;
}

.answer {
  @include textExtraLarge;

  color: $black;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}
