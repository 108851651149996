@import '@assets/scss/v2/main';

.container {
  display: flex;
  width: 100%;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10rem;
}
