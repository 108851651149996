@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.simpleLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 128rem;
  justify-content: center;

  @include respond(xs) {
    margin: 1.6rem;
  }

  @include respond(s) {
    margin: 2.4rem;
  }

  @include respond(m) {
    margin: 3.2rem;
  }

  @include respond(l) {
    margin: 2.4rem 4rem;
  }

  @include respond(xl) {
    margin: 4rem 8rem;
  }

  @include respond(xxl) {
    margin: 4rem 8rem;
  }
}
