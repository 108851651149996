@import '@assets/scss/v2/main';

.container {
  width: 100%;
  max-width: 600px;
}

.title {
  max-width: 600px;
  margin: 2rem 0;
}

.infoText {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include textQuadrupleExtraLargeMediumBold;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}
