@import '@assets/scss/v2/main';

.filteringSection {
  margin-bottom: 1.6rem;
}

.sectionTitle {
  @include subheading;
}

.checkboxContainer {
  padding: 1.2rem;
}

.footerContainer {
  border-top: 1px solid $silver;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 1.6rem 1.6rem 3.2rem 1.6rem;
  position: absolute;
  width: 100%;
}

.rejectButton {
  white-space: nowrap;
}

.confirmButton {
  width: 100%;

  @include respond(l) {
    width: initial;
  }
}