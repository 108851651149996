@import '@assets/scss/v2/main';

.wrapper {
  background: $white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-bottom: 2.4rem;
}

.title {
  font-family: 'Quicksand-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 120%;
  margin-bottom: 1.6rem;
  margin-top: 2.4rem;
}

.productName {
  font-family: 'Quicksand-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: $blue-bright;
  padding: 1rem;
  border-bottom: 0.1px solid rgba(0, 0, 0, 0.2);
}

.imageWrapper {
  width: 194px;
  height: 235px;
  flex-shrink: 0;
  margin: 2rem 0 2rem 2rem;

  img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
  }
}

.contractInfoWrapper {
  display: flex;
  padding: 0.5rem 0rem;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }
}

.contractInfo {
  padding: 2rem 2.4rem 0;
  margin-bottom: 4rem;
}

.contractDurationWrapper {
  margin-bottom: 1.6rem;
}

.contractDuration {
  font-family: 'Quicksand-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 100%;
  margin-bottom: 1rem;
}

.datePill {
  display: inline-block;
  background-color: $white-smoke;
  border-radius: 8rem;
  padding: 0.4rem 1.2rem;

  font-family: 'Quicksand-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.dot {
  display: flex;

  &:before {
    content: '';
    margin-right: 0.7rem;
    display: inline-block;
    align-self: center;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: $blue-bright;
  }
}

.till {
  font-family: 'Quicksand-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.additionalServices {
  margin: 1.6rem 0rem;
}
