@import '@assets/scss/v2/main';

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.regularMargin {
    padding: $spacing-m;
    gap: $spacing-m;
  
    @include respond(s) {
      padding: 0 $spacing-l;
      gap: $spacing-l;
    }
  
    @include respond(m) {
      padding: 0 $spacing-xl;
      gap: $spacing-l;
    }
  
    @media only screen and (min-width: 1280px) {
      padding: 0;
      gap: $spacing-2xl;
    }
  
    @include respond(xl) {
      gap: $spacing-2xl;
    }
  }

@mixin stackingFlex {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  
    @media only screen and (min-width: 1280px) {
      flex-direction: row;
      width: 128rem;
      margin: 0 $spacing-2xl;
    }
  
    @include respond(xl) {
      flex-direction: row;
      width: 128rem;
      margin: 0 8rem;
    }
  
    @include respond(xxl) {
      flex-direction: row;
      width: 128rem;
      margin: 0 8rem;
    }
  }


.stackingLayout {
    @include stackingFlex;
}

.innerWrapper {
    @include respond(m) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.allCategoriesLabelButtonText {
    @include textSmallBold;
    line-height: 130%;
}

.labelButton {
    all: unset;
    margin-right: 1rem;
    display: none;

    @include respond(m) {
        display: initial;
      }

      &:hover {
        cursor: pointer;
    }
}

.sheetButton {
    all: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $spacing-m;
    gap: 16px;

    .filterImage {
        height: 8.5rem;
    
        img {
            height: 8.5rem;
            width: 7.8rem;
            object-fit: cover;
        }
    }

    .productCategoryText {
        @include textLargeBold;
        line-height: 100%;
    }

    &:hover {
        cursor: pointer;
    }
}

.filterRowWrapper {
    padding-top: 5.7rem;
}

.subTitleFilterRowText {
  @include textLargeBold;
    line-height: 165%;
    margin-bottom: 0.9rem;
}

.inactiveCategory {
    opacity: 40%;
}
