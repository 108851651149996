@import '@assets/scss/v2/main';

.fullNavigation {
  display: none;

  @include respond(l) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 3;

    .imageWrapper {
      display: flex;
      justify-content: center;
      border: 1px solid $silver;
      border-radius: 4px;
      margin: 0.8rem;
      padding: 0.5rem;
      height: 7.2rem;
      width: 11.1rem;
      cursor: pointer;

      @include textExtraSmallBold;

      .image {
        height: 6.1rem;
        width: 6.1rem;
      }

      &.currentImage {
        border: 1px solid $black;
        border-radius: 4px;
      }

      &.showMoreContainer {
        position: relative;
        cursor: pointer;

        span {
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          padding-bottom: 1rem;
          z-index: 2;
        }

        .image {
          opacity: 0.5;
          height: 6.1rem;
          width: 6.1rem;
        }
      }
    }
  }
}
