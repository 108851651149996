@import '@assets/scss/v2/main';

.container {
  display: none;
  flex-direction: column;
  flex: 1;
  color: $black;
  list-style-type: none;
  margin: 1rem 0;

  @include respond(m) {
    display: flex;
  }
}

.infoLinks {
  display: flex;
  flex-direction: column;
  margin: 3.2rem 0;
  min-width: 20rem;
  width: 100%;
  list-style-type: none;
}

.itemContainer {
  &:not(:last-child) {
    border-bottom: 0.5px solid $black;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
}

.item {
  display: flex;
  flex-direction: row;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.icon {
  margin-right: 1rem;
  width: 2.5rem;
  line-height: 2rem;
}

.titleText {
  @include subtitle;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.titleBodyText {
  @include textLight;
}

.subtitleBodyText {
  @include textSmallLight;
}