.takeoverProceedButton {
  margin-top: 2.4rem;
  margin-bottom: 9rem;
  width: 100%;

  button {
    width: 100%;
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  height: 5rem;
  width: 100%;
}
