@import '@assets/scss/v2/main';

.container {
  display: flex;
  width: 100%;
  user-select: none;
  max-width: 100%;

  @include respond(l) {
    max-width: 45rem;
  }

  *:focus {
    outline: none;
  }
}

.imageCarousel {
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  width: 100%;
}

.arrowsContainer {
  display: none;
}

.topContainer {
  display: flex;
  margin-bottom: 1.2rem;
  align-self: center;

  @include respond(m) {
    margin-bottom: 2.4rem;

    &:hover {
      .arrowsContainer {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;

        .arrow {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 5.8rem;
          height: 5.8rem;
          background: $white;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
          border-radius: 50px;
          user-select: none;
          cursor: pointer;
          z-index: 3;

          .vectorIconRotated {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.mainImage {
  position: relative;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  cursor: pointer;
  z-index: 2;

  @include respond(m) {
    min-width: 45rem;
  }
}

.image {
  display: flex;
  width: 100%;

  img {
    display: block;
    width: 100% !important;
    height: auto !important;
  }
}

.scrollNavigationImage {
  display: flex;
  border: 1px solid $silver;
  border-radius: 4px;
  margin: 0.8rem 0.2rem;
  padding: 0.5rem;
  width: 7.2rem;
  height: 7.2rem;
  min-width: 7.2rem;
  min-height: 7.2rem;
  max-width: 7.2rem;
  max-height: 7.2rem;
  cursor: pointer;

  &.currentImage {
    border: 1px solid $black;
    border-radius: 4px;
  }
}
