@import '@assets/scss/v2/main';

.blogpostCategoryAndReadingTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: start;
    margin-bottom: 2.1rem;

    .iconSpacing {
        display: flex;
        margin: 0 0.4rem 0 2.3rem
    }

    .readingTime {
        @include textSmallBold;
    }
}