@import '@assets/scss/v2/main';

.container {
  display: flex;
  position: fixed;
  flex-direction: column;
  max-width: 43.2rem;
  max-height: 100vh;
  width: 100%;
  padding: 2.4rem 1.6rem;
  z-index: 11;
  background-color: $white;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  overflow-y: scroll;
  scrollbar-width: none;

  @include respond(m) {
    margin: 0;
    top: 2.4rem;
    right: 2rem;
    bottom: 2.4rem;
    transform: none;
    max-height: 95vh;
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 4rem 0 1rem 0;
  border-bottom: 1px solid $grey-eb;
}

.headerPlaceholder {
  min-height: 8rem;

  @include respond(m) {
    display: none;
  }
}

.titleIcon {
  max-width: max-content;
  margin: 0 auto;
}

.title {
  @include headingMediumTitle;

  max-width: max-content;
  margin: 1rem auto;
  color: $blue-bright;
}

.cartItems {
  display: flex;
  flex-direction: column;
  width: 100%;
  // min-height: 10rem;
}

.discountContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  margin-top: 1.5rem;
  padding: 1.2rem;
  border-radius: 0.4rem;
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
}

.discountHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.discountBody {
  margin: 1rem 0 0 6rem;
  width: 100%;
}

.checkbox {
  display: flex;
  width: 2rem;
  height: 2rem;
  border: 2px solid $grey-tabby;
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 1rem;
  justify-content: center;
  align-items: center;
  background-color: $white;
}

.checkboxChecked {
  background-color: $blue-bright;
  border: 2px solid $blue-bright;
  padding: 0.2rem;
}

.discountCodeTextButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.discountInput {
  border: 2px solid $silver;
  box-sizing: border-box;
  border-radius: 0.4rem;
  background-color: $white;
  height: 4.5rem;
  padding: 0 1rem;

  &:focus {
    border-color: $grey-tabby;
    outline: none;
  }
}

.discountInputError {
  border-color: $error;
}

.discountCodeText {
  display: inline-flex;
  width: 100%;
  margin: 1.5rem 0 0 6rem;
  gap: 1rem;
  align-items: center;
}

.removeDiscountButton {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.discountCheckButton {
  background-color: $blue-bright;
  color: $white;
  border-radius: 3px;
  border: 0;
  padding: 1rem 3rem;
  height: 4.5rem;
  margin: 0 1rem;
}

.infoBoxContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.checkoutButton {
  @include textLargeBold;

  width: 100%;
  background: $blue-bright;
  color: $white;
  border: none;
  border-radius: 0.4rem;
  padding: 1.5rem 1.2rem;
  margin: 2rem 0;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.emptyCart {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
}

.showAllProductsButton {
  @include textMediumBold;

  width: 100%;
  background: $white;
  border: none;
  border-radius: 0.4rem;
  padding: 1.5rem 1.2rem;
  margin: 0.5rem 0 2rem 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.hidden {
  display: none;
}

.stroke {
  text-decoration: line-through;
}

.closeButtonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
}

.shareButton,
.closeButton {
  width: 4.8rem;
}

.backgroundVisible {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 350ms;
  z-index: 10;

  &:hover {
    cursor: pointer;
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40rem;
}
