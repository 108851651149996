@import '@assets/scss/v2/main';

.container {
  margin-top: 1rem;
  color: $black;
  
  @include respond(m) {
    display: flex;
    justify-content: center;
  }

  @include respond(xl) {
    flex-direction: row;
    margin: 5rem 15rem 0 10rem;
    gap: 3rem;
  }

  @include respond(xxl) {
    margin: 5rem 20rem 0 15rem;
  }
}

.sidebar {
  display: none;

  @include respond(xl) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.mainSection {
  display: flex;
  flex-direction: column;
  
  @include respond(m) {
    max-width: 72rem;
  }
}
