@import '@assets/scss/v2/main';

@mixin stackingFlex {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media only screen and (min-width: 1280px) {
    flex-direction: row;
    margin: 0 4rem;
  }

  @include respond(xl) {
    flex-direction: row;
    margin: 0 8rem;
  }

  @include respond(xxl) {
    flex-direction: row;
    width: 128rem;
    margin: 0 8rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.regularMargin {
  gap: 1.6rem;

  @include respond(s) {
    gap: 1.6rem;
  }

  @include respond(m) {
    gap: 2.4rem;
  }

  @media only screen and (min-width: 1280px) {
    gap: 3.2rem;
  }

  @include respond(xl) {
    gap: 3.2rem;
  }
}

.stackingLayout {
  @include stackingFlex;
}

.responsiveColumns {
  @include respond(m) {
    flex: 1 0 100%;
    max-width: calc(50% - 1.6rem);
    flex-grow: 1;
    flex-basis: calc(50% - 3.2rem);
  }

  @media only screen and (min-width: 1280px) {
    flex: 1 0 100%;
    max-width: calc(33% - 1.6rem);
    flex-grow: 1;
    flex-basis: calc(33% - 3.2rem);
  }
}

.item {
  display: flex;
  justify-content: center;
  width: 100%;

  & > * {
    width: 100%;

    & > * {
      max-width: 100% !important;
    }
  }

  @include respond(m) {
    margin: 0;
  }
}

.filterBtn {
  width: 100%;
  margin-bottom: 2rem;
}
