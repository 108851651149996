@import '@assets/scss/v2/main';

.container {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  height: 40px;
  width: max-content;
  max-width: 100%;
}

.icon {
  height: 24px;
}
