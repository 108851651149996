@import '@assets/scss/v2/main';

.container {
  position: relative;
  width: 100%;
  padding: 5.6rem 3.2rem 5.6rem 0;

  @include respond(s) {
    padding: 5.6rem 4rem 5.6rem 0;
  }

  @include respond(m) {
    padding: 5.6rem 0;
  }
}

.containerNarrow {
  padding: 0;
}

.imageContainer {
  position: absolute;
  top: 0;
  left: 1.6rem;
  bottom: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
  border-radius: 0 3px 3px 0;

  @include respond(s) {
    left: 2.4rem;
  }

  @include respond(m) {
    left: 5.6rem;
  }
}

.videoContainer {
  z-index: 1 !important;
}

.imageContainerRight {
  @include respond(m) {
    left: 0;
    right: 5.6rem;
  }
}

.imageContainerWide {
  left: 11.2rem;
  right: 0;
}

.imageContainerWideRight {
  right: 11.2rem;
  left: 0;
}

.imageContainerNarrow {
  display: none;

  @include respond(m) {
    display: block;
    top: 0;
    left: 50%;
    bottom: 0;
    width: 50%;
    border-radius: 0 3px 3px 0;
  }
}

.imageContainerNarrowRight {
  display: none;

  @include respond(m) {
    display: block;
    top: 0;
    left: 0;
    width: 50%;
    bottom: 0;
    border-radius: 3px 0 0 3px;
  }
}

.imageContainerInset {
  display: none;

  @include respond(m) {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.contentContainer {
  width: 25.6rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  padding: 5rem 3rem;
  border-radius: 3px;

  @include respond(s) {
    width: 30.3rem;
  }

  @include respond(m) {
    padding: 5.6rem;
    width: 47.2rem;
    max-width: none;
  }
}

.borderRadiusForVideo {
  border-radius: 3px 0 0 3px;
}

.contentContainerRight {
  @include respond(m) {
    margin-left: auto;
  }
}

.contentContainerWide {
  padding: 5rem 3rem;

  @include respond(m) {
    width: 52rem;
    padding: 5.6rem;
  }
}

.contentContainerNarrow {
  position: static;
  width: 100%;
  height: 100%;
  padding: 1.6rem;

  @include respond(s) {
    padding: 2.4rem;
  }

  @include respond(m) {
    padding: 2.6rem 2.6rem 3.4rem 2.6rem;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%;
  }

  @include respond(l) {
    padding: 2.6rem 2.6rem 3.4rem 2.6rem;
  }

  @include respond(xl) {
    padding: 5.6rem 5.6rem 6.4rem 5.6rem;
  }
}

.contentContainerNarrowRight {
  @include respond(m) {
    width: 50%;
    top: 0;
    bottom: 0;
    left: auto;
    right: 0;
    border-radius: 0 3px 3px 0;
  }
}

.contentContainerInset {
  width: 100%;
  padding: 1.6rem;

  @include respond(s) {
    padding: 2.4rem;
  }

  @include respond(m) {
    width: calc(60% - (5.6rem + 5.6rem));
    margin-left: 5.6rem;
  }
}

.contentContainerInsetRight {
  width: 100%;
  padding: 1.6rem;

  @include respond(s) {
    padding: 2.4rem;
  }

  @include respond(m) {
    width: calc(60% - (5.6rem + 5.6rem));
    margin-left: calc(40% + 5.6rem);
  }
}

.contentContainerBackgroundBlueBright {
  background-color: $blue-bright;

  > * {
    color: $white;
  }
}

.contentContainerBackgroundBlueDark {
  background-color: $blue-dark;

  > * {
    color: $white;
  }
}

.contentContainerBackgroundBlueLight {
  background-color: $blue-light;

  > * {
    color: $black;
  }
}

.contentContainerBackgroundBrandYellow {
  background-color: $brand-yellow;

  > * {
    color: $black;
  }
}

.contentContainerBackgroundBrandPink {
  background-color: $brand-pink;

  > * {
    color: $black;
  }
}

.contentContainerBackgroundBrandGreen {
  background-color: $brand-green;

  > * {
    color: $black;
  }
}

.image {
  height: 100%;
  width: 100%;
  display: flex;

  > img {
    object-fit: cover;
  }
}

.middleItemPaddings {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.richTextContentContainer {
  gap: 1.6rem;

  @include respond(l) {
    gap: 2rem;
  }

  p {
    @include textMedium;

    color: unset;
  }

  h1 {
    @include headingSubtitleBold;

    color: unset;
  }

  li {
    padding-top: 0.8rem;
  }
}

.playerFullHeight {
  > * {
    height: 100% !important;
  }
}
