@import '@assets/scss/v2/main';

.container {
  width: 100%;
}

.radioLabel {
  display: flex;
  align-items: center;
}

.radioLabelTextContainer {
  margin-left: 0.85rem;
  display: flex;
  flex-direction: column;
}

.radioLabelLightText {
  @include font-sans;
  @include font-weight-normal;
  @include text-sm;
  @include line-height-relaxed;
  line-height: 1.75rem;
}

.generalConsentCheckbox {
  margin-top: 4rem;
  margin-bottom: 1.6rem;
}

.generalConsentCheckboxV2 {
  margin-top: -2rem;
  margin-bottom: 1.6rem;
}

.generalConsentCheckboxLink {
  text-decoration: underline;
}

.radioLabelNameText {
  @include textBold;
}

.expiredCouponNotification {
  margin-bottom: 2.4rem;
}
