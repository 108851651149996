@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: row;
  margin: 1.6rem 0 2.4rem;
  width: 100%;
  flex-wrap: wrap;
  max-width: 100%;
  min-width: 33.6rem;
  gap: 0 1.1rem;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  padding: 1.6rem 1.2rem;
  background-color: $white;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10rem;
}

.iconContainer {
  width: 1.8rem;
  height: 1.8rem;
}

.changeIcon {
  width: 1.8rem;
  height: 1.8rem;
}

.addressContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.shippingAddress {
  @include text;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bold {
  @include textBold;
  line-height: 1;
}

.separator {
  margin-top: 0.8rem;
}
