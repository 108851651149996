@import '@assets/scss/v2/main';

$radioSize: 2rem;

.radioContainer {
  display: flex;
  flex-direction: row;
  color: $black;
  user-select: none;
  align-items: center;
  cursor: pointer;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  margin-bottom: 0.8rem;
  width: 100%;
  border-radius: 0.4rem;
}

.grayLabel {
  background-color: $grey-alabaster;
  box-shadow: none;
}

.whiteLabel {
  background-color: $white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}

.labelText {
  color: $black;
  margin-left: 1.2rem;
}

.externalText {
  margin-top: 2.1rem;
  font-weight: 400;
}

.radio {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: $radioSize;
  max-height: $radioSize;
  height: $radioSize;
  min-width: $radioSize;
  max-width: $radioSize;
  width: $radioSize;
  border: 50% solid $black;
  border-radius: 2px;
  background-color: $white;
}

.selected {
  background-color: $blue-bright;
  border: none;
}

.disabled {
  opacity: 0.2;
}

.radioContainer {
  border-radius: 4px;

  input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ .checkmark {
    background-color: $white;
    border: $blue-bright 0.2rem solid;
  }

  &:hover input ~ .checkmark:after {
    position: absolute;
    content: '';
    width: 1rem;
    height: 1rem;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background-color: $blue-bright;
    border-radius: 50%;
  }

  &:hover input:checked ~ .checkmark:after {
    background-color: $white;
  }

  input:checked ~ .checkmark {
    background-color: $white;
    border: $blue-bright 0.5rem solid;
  }
}

.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  min-width: 2rem;
  background-color: $white;
  border: 2px solid $black;
  border-radius: 50%;
  color: $black;
  margin-right: 1.2rem;
}

.errorMessage {
  @include textExtraSmall;

  margin-top: 1.2rem;
  color: $error;
  margin-left: 0.5rem;
}

.fieldContainer {
  width: 100%;
}

.labelWithBorder {
  border: 2px solid transparent;
}
