@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include respond(m) {
    margin: 0 2.4rem;
  }
}

.carousel {
  display: flex;

  @include respond(xs) {
    padding: 0 -1.6rem;
  }

  @include respond(s) {
    padding: 0 -2.4rem;
  }

  > * {
    &::-webkit-scrollbar {
      margin: 0 1.5rem;
      background-color: $blue-solitude;
      border-radius: 16px;
      height: 0.6rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $brand-pink;
      border-radius: 16px;
    }
  }
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}
