@import '@assets/scss/v2/main';
@import '@assets/scss/shared/variables';

.wrapper {
  position: absolute;
  background-color: $white;
  left: 0;
  width: 100%;
  height: calc(100vh - 7rem);
  z-index: 9999;
  overflow-y: auto;
  top: 60px;
  border-top: 1px solid $silver-light;
  padding-bottom: 8rem;

  @include respond(m) {
    top: 75px;
  }

  @include respond(xl) {
    display: none;
  }
}

.topPosition {
  top: 60px;

  @include respond(m) {
    top: 75px;
  }
}

.additionalTopPosition {
  top: 90px;

  @include respond(m) {
    top: 105px;
  }
}

.listWrapper {
  list-style-type: none;
}

.currentLanguage {
  padding-left: 2.4rem;
}

.localizationSelectItem {
  align-items: center;
  display: flex;
}

.localizationChangeButton {
  padding-inline: 0;
  width: 100%;

  &:hover {
    background: transparent;
  }
}