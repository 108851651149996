@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  min-width: 33.6rem;
  gap: 0 1.1rem;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  padding: 1.6rem 1.2rem;
  background-color: $white;
  margin: 1.6rem 0 2.4rem;
}

.iconContainer {
  width: 1.8rem;
  height: 1.8rem;
}

.addressContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.editText {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
}

.accountIcon {
  width: 1.8rem;
  height: 1.8rem;
}

.shippingAddress {
  @include text;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.topMargin {
  margin-top: 1.6rem;
}

.bold {
  @include textBold;
  line-height: 1;
}

.addressNotCorrectText {
  @include text;
  @include underline;

  cursor: pointer;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10rem;
}

.link {
  width: 100%;
  cursor: pointer;
  text-decoration: underline;
}
