.wrapper {
    display: flex;
    justify-content: center;
}

.container {
    width: 64.5rem;
    padding: 3rem;

    & [class*="TitleWithText"] {
        padding: 0;
        width: auto;
    }
}