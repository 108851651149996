@import '@assets/scss/v2/main';

$height: 7.3rem;
$marginFromViewport: 3.2rem;

@keyframes goUp {
  from {
    bottom: -$height;
  }

  to {
    bottom: $marginFromViewport;
  }
}

.container {
  display: flex;
  position: fixed;
  bottom: $marginFromViewport;
  animation: goUp 800ms;
  width: calc(100% - 2rem);
}

.floatingActionBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: $height;
  max-height: $height;
  background-color: $white;
  padding: 0 1.6rem;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.251);
  border-radius: 8px;
  width: 100%;

  .leftSection {
    display: none;
  }

  @include respond(m) {
    padding: 0 2.4rem;

    .leftSection {
      display: flex;
      flex-direction: column;
      text-align: left;

      .textTop {
        @include textLargeBold;

        color: $black;
        margin-bottom: 0.1rem;
      }

      .textBottom {
        @include text;

        color: $grey-tabby;
        text-decoration: underline;
      }
    }
  }

  .rightSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    @include respond(m) {
      width: auto;
    }

    .textContainer {
      display: none;
    }

    .buttonContainer {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      > div {
        width: 100%;

        > * {
          width: 100%;
        }
      }

      @include respond(m) {
        display: flex;
        justify-content: flex-end;
        width: auto;

        > div {
          width: 100%;

          > div {
            width: auto;
          }
        }
      }
    }

    @include respond(m) {
      .textContainer {
        display: flex;
        flex-direction: column;
        text-align: right;
        margin-right: 1.6rem;
        width: auto;

        .textTop {
          @include textLargeBold;

          color: $black;
          margin-bottom: 0.1rem;
        }

        .textBottom {
          @include text;

          color: $grey-tabby;
        }
      }
    }
  }
}
