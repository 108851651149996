@import '@assets/scss/v2/main';
@import '@assets/scss/v2/breakpoints';

.container {
  position: fixed;
  top: 0;
  transition: 0.2s ease-in;
  display: flex;
  height: 50%;
  background-color: transparent;
  z-index: 999;
  overflow: auto;
}

.containerLeft {
  top: 0;
  height: 100%;
  transform: translateX(-100%);
  left: 0;
}

.containerLeftOpen {
  transform: translateX(0);
}

.containerRight {
  right: 0;
  height: 100%;
  top: 0;
  transform: translateX(100%);
  flex-direction: row-reverse;
  overflow: hidden;
}

.containerRightOpen {
  right: 0;
  transform: translateX(0);
}

.containerBottom {
  width: 100%;
  max-height: 100%;
  bottom: 0;
  height: auto;
  flex-direction: column-reverse;
  left: 50%;
  transform: translate(-50%, 100%);
}

.containerBottomOpen {
  bottom: 0;
  max-height: 100%;
  transform: translate(-50%, 0);
  left: 50%;
}

.contentContainer {
  width: 32rem;
  color: $black;
  padding: 0 2.4rem 1rem 2.4rem;
  overflow: auto;
  background-color: $white;

  @include respond(m) {
    width: 44.8rem;
  }
}

.contentContainerBottom {
  width: 100%;
}

.heading {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  min-height: 4.4rem;
  align-items: center;
  justify-content: space-between;
  font-family: $font-quicksand;
  color: $black;
  position: fixed;
  top: 0;
  background-color: $white;
  padding-top: 0.3rem;
  font-weight: 700;
  width: calc(32rem - 2.4rem - 2.4rem);

  @include respond(m) {
    width: calc(44.8rem - 2.4rem - 2.4rem);
  }
}

.content {
  margin-top: 7.4rem;
}

.closeIconWrapper {
  display: flex;
}

.closeIcon {
  cursor: pointer;

  >circle {
    display: none;
  }
}

.shadowLeft {
  width: 16px;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0) 50%);
  border-right: 0.5px solid #ededed;
  background-color: transparent;
}

.shadowRight {
  width: 16px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0) 50%);
  border-left: 0.5px solid #ededed;
}

.shadowTop {
  height: 16px;
  width: 100%;
  background: linear-gradient(0.01deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0) 50%);
  border-bottom: 0.5px solid #ededed;
}

.titleText {
  @include textBold;
}
