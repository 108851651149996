@import '@assets/scss/v2/main';

.container {
  display: flex;
}

.textContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin: 0;
  color: $black;
}

.linkedText {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 0.5rem;
  user-select: none;
  color: $black;
}

.text {
  @include textLargeBold;
}
