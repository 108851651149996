.container {
  display: flex;
  min-height: 2rem;

  iframe {
    height: 100% !important;
  }
}

// Widgets types
.mini {
  height: 11.5rem;
}

.microStar {
  height: 2rem;
}

.microReviewCount {
  height: 2.1rem;
}

.huge {
  height: 25rem;
}
