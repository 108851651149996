@import '@assets/scss/v2/main';

.icon {
  width: 2.4rem;
  height: 2.4rem;
  border: 1px solid $base-grey;
  border-radius: 50%;
}

.languageOption {
  cursor: pointer;
  background: transparent;
  border: none;
  color: $black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: .8rem;
  font-weight: bold;
}
