// $breakpointXS: 320px;
$breakpointXS : 0px;
$breakpointS  : 376px;
$breakpointM  : 768px;
$breakpointL  : 900px;
$breakpointXL : 1200px;
$breakpointXXL: 1680px;

@mixin respond($breakpoint) {
  @if $breakpoint==xs {
    @media only screen and (min-width: $breakpointXS) {
      @content;
    }
  }

  @if $breakpoint==s {
    @media only screen and (min-width: $breakpointS) {
      @content;
    }
  }

  @if $breakpoint==m {
    @media only screen and (min-width: $breakpointM) {
      @content;
    }
  }

  @if $breakpoint==l {
    @media only screen and (min-width: $breakpointL) {
      @content;
    }
  }

  @if $breakpoint==xl {
    @media only screen and (min-width: $breakpointXL) {
      @content;
    }
  }

  @if $breakpoint==xxl {
    @media only screen and (min-width: $breakpointXXL) {
      @content;
    }
  }

  @else {
    @media only screen and (min-width: $breakpoint) {
      @content;
    }
  }
}