@import '@assets/scss/v2/main';

@mixin mobileContainer {
  display: flex;
  height: 100%;
  min-width: 100%;
  max-width: 100%;
  flex: 1 1 auto;
}

@mixin mobileCategoryCard {
  display: flex;
  flex-direction: row;
  flex: 1;
}

@mixin mobileImageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.6rem;
}

@mixin mobileBackground {
  height: 8.5rem;
  width: 8.5rem;
  margin-top: 0;
  z-index: 1;
  border-radius: 3px;
}

@mixin mobileImage {
  display: flex;
  position: absolute;
  z-index: 2;
  height: 7.2rem;
  padding-bottom: 0;
  border-style: none;
}

@mixin mobileHeader {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  color: $black;
  justify-content: center;
  gap: 0.5rem;
  flex: 1;
}

@mixin mobileContent {
  margin-top: 1.6rem;
  color: $black;
  flex: 1;
  display: none;
}

@mixin mobileLinkText {
  display: none;
}

@mixin mobileLink {
  color: $black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 1rem;
}

.onlyMobile {
  .container {
    @include mobileContainer;
  }

  .categoryCard {
    @include mobileCategoryCard;
  }

  .imageContainer {
    @include mobileImageContainer;
  }

  .background {
    @include mobileBackground;
  }

  .image {
    @include mobileImage;
  }

  .header {
    @include mobileHeader;
  }

  .content {
    @include mobileContent;
  }

  .linkText {
    @include mobileLinkText;
  }

  .link {
    @include mobileLink;
  }
}

.container {
  @include mobileContainer;

  @include respond(l) {
    min-width: 22rem;
    max-width: 40rem;
  }
}

.categoryCard {
  @include mobileCategoryCard;

  @include respond(l) {
    flex-direction: column;
    width: auto;
  }
}

.imageContainer {
  @include mobileImageContainer;

  @include respond(l) {
    width: auto;
    margin-right: 0;
  }
}

.background {
  @include mobileBackground;

  @include respond(l) {
    height: 24rem;
    width: 100%;
    margin-top: 3.2rem;
  }
}

.image {
  @include mobileImage;
  @include respond(l) {
    height: 27.5rem;
    padding-bottom: 3.4rem;
  }

  justify-content: center;

  img {
    width: auto;
    max-height: 100%;
    max-width: fit-content;
  }
}

.header {
  @include mobileHeader;

  @include respond(l) {
    gap: 0;
    margin-top: 2.4rem;
    justify-content: flex-start;
  }
}

.content {
  @include text;
  @include mobileContent;

  @include respond(l) {
    display: flex;
  }
}

.mobileButton {
  display: flex;
  align-items: center;
  width: 4.4rem;
  & svg {
    transform: rotate(90deg);
  }
  @include respond(l) {
    display: none;
  }
}

.button {
  display: none;
  align-items: center;
  margin-top: 2rem;
  margin-left: 0;
  justify-content: space-between;

  @include respond(l) {
    display: flex;
  }
}

.linkText {
  @include textBold;
  @include mobileLinkText;

  @include respond(l) {
    display: flex;
  }
}

.link {
  @include mobileLink;

  @include respond(l) {
    margin-left: 0;
  }
}

.headerText {
  @include textLargeMediumBold;

  @include respond(l) {
    @include textDoubleExtraLargeBold;
  }
}

.headerSubtext {
  @include textExtraSmall;

  @include respond(l) {
    @include textSmallBold;
  }
}
