@import '@assets/scss/v2/main';

.input {
  display: none;
}

.label {
  position: relative;
  width: 100%;
  border-radius: 4px;
  padding-left: 3rem;
  cursor: pointer;
}

.label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  border: 2px solid $grey-generic;
  background-color: $white;
  border-radius: 2px;
}

.input:checked + .label::before {
  background-image: url('../../../../assets/images/Checkmark.png');
  border: none;
}

.input:checked:disabled + .label::before {
  opacity: 0.5;
}
