@import '@assets/scss/v2/main';

.link {
  display: flex;
}

.row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);

  &:last-child {
    border-bottom: transparent;
  }
}

.cell {
  text-overflow: ellipsis;
  overflow: hidden;
  @include respond(m) {
    @include text;
  }
}
.icons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.header {
  @include textExtraSmallBold;
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  svg {
    margin-left: 0.5rem;
    width: 1.2rem;
  }
}

.value {
  margin-top: 0.4rem;
  margin-bottom: 0.8rem;

  .text {
    @include textSmall;
  }
}

.icon > img {
  width: 4rem;
  height: 4rem;
  margin-right: 0.8rem;
}

.energyLabelContainer {
  margin-right: 1.2rem;
}

.infoIcon {
  height: 12px;
  width: auto;
}

.iconContainer {
  cursor: pointer;
  padding: 0.6rem 1.2rem 0.6rem;
  border-radius: 0.4rem;
}

.iconBasicColor {
  background: rgba(156, 213, 112, 0.4);
}

.iconCloseColor {
  background: $blue-solitude;
}

.iconLabel {
  @include textSmallBold;
}
