@import '@assets/scss/v2/main';

.container {
  display: flex;
  width: 100%;
  margin: 4rem 0;
  justify-content: center;
}

.scrollNavigation {
  width: unset !important;
  padding: 1rem 1.6rem 3.2rem;
  gap: 2.4rem;

  @include respond(xs) {
    padding: 1rem 1.6rem 3.2rem;
  }

  @include respond(s) {
    padding: 1rem 2.4rem 3.2rem;
  }

  @include respond(m) {
    padding: 1rem 3.2rem 3.2rem;
  }

  @include respond(l) {
    padding: 1rem 4rem 3.2rem;
  }

  @include respond(xl) {
    padding: 1rem 8rem 3.2rem;
  }

  @include respond(xxl) {
    padding: 1rem 8rem 3.2rem;
  }
}

.itemContainer {
  scroll-snap-align: center;
  display: flex;
}

.extend {
  display: flex;
  flex-grow: 1;
}

.cursorPointer {
  cursor: pointer;
}