$breakpointXS: 380px;
$breakpointSM: 768px;
$breakpointMD: 1024px;
$breakpointMaxGrid: 1440px;
$breakpointLG: 1920px;

@mixin respond($breakpoint) {
  @if $breakpoint == 2xs {
    @media only screen and (max-width: $breakpointXS) {
      @content;
    }
  }

  @if $breakpoint == xs {
    @media only screen and (max-width: $breakpointSM) {
      @content;
    }
  }

  @if $breakpoint == sm {
    @media only screen and (min-width: $breakpointSM) {
      @content;
    }
  }

  @if $breakpoint == md {
    @media only screen and (min-width: $breakpointMD) {
      @content;
    }
  }

  @if $breakpoint == maxGrid {
    @media only screen and (min-width: $breakpointMaxGrid) {
      @content;
    }
  }

  @if $breakpoint == lg {
    @media only screen and (min-width: $breakpointLG) {
      @content;
    }
  } @else {
    @media only screen and (min-width: $breakpoint) {
      @content;
    }
  }
}
