@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  min-width: 28rem;
  gap: 0 1.1rem;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  padding: 1.6rem 1.2rem;
  background-color: $white;
  margin: 1.6rem 0 2.4rem;
}

.informationContainer {
  display: flex;
  &:last-child {
    margin-top: 1.6rem;
  }
}

.iconContainer {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;
}

.iconLocationContainer {
  margin-left: -0.3rem;
  margin-right: 1.1rem;
}

.addressContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contentContainer {
  @include text;

  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  flex-wrap: wrap;
  width: 100%;
}

.titleContainer {
  display: flex;
  width: 100%;
  text-align: left;
}

.bold {
  @include textBold;
  line-height: 1;
}

.underline {
  text-decoration: underline;
}

.title {
  p {
    @include textLargeBold;
  }
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10rem;
}

.emailEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 1rem);
}
