@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $blue-bright;
  width: 100%;
  min-height: 29rem;
  padding: 2.4rem;

  @include respond(m) {
    margin-top: 0;
  }
}

.headerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4.8rem;
}

.title {
  @include textQuadrupleExtraLargeMediumBold;

  color: $white;
  margin-bottom: 1.2rem;
  text-align: center;
}

.description {
  @include text;

  color: $white;
}

.formContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.inputContainer {
  width: 100%;
  max-width: 80rem;
}

.buttonContainer {
  max-width: 17.8rem;
}

.button {
  margin: 0;
  height: 5.4rem;
}
