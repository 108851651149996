@import './variables.scss';

@mixin default-font-color {
  color: $black;
}

// FONT FAMILY
@mixin font-sans {
  font-family: $font-quicksand;
  font-weight: 400;
}

@mixin font-sans-medium {
  font-family: $font-quicksand;
  font-weight: 500;
}

@mixin font-sans-bold {
  font-family: $font-quicksand;
  font-weight: 700;
}

@mixin font-sans-semi-bold {
  font-family: $font-quicksand;
  font-weight: 600;
}

@mixin font-sans-medium {
  font-family: $font-quicksand;
  font-weight: 500;
}

// FONT SIZE
@mixin text-xxs {
  font-size: 1.1rem;
}

@mixin text-xs {
  font-size: 1.2rem;
}

@mixin text-sm {
  font-size: 1.4rem;
}

@mixin text-base {
  font-size: 1.6rem;
}

@mixin text-lg {
  font-size: 1.8rem;
}

@mixin text-xl {
  font-size: 2rem;
}

@mixin text-2xl {
  font-size: 2.4rem;
}

@mixin text-3xl {
  font-size: 2.8rem;
}

@mixin text-4xl {
  font-size: 3.2rem;
}

@mixin text-5xl {
  font-size: 3.8rem;
}

@mixin text-6xl {
  font-size: 4.8rem;
}

// FONT WEIGHT
@mixin font-weight-normal {
  font-weight: 400;
}

@mixin font-weight-medium {
  font-weight: 500;
}

@mixin font-weight-bold {
  font-weight: 700;
}

// LINE HEIGHT
@mixin line-height-none {
  line-height: 1;
}

@mixin line-height-thight {
  line-height: 1.2;
}

@mixin line-height-normal {
  line-height: 1.3;
}

@mixin line-height-button {
  line-height: 1.4;
}

@mixin line-height-relaxed {
  line-height: 1.5;
}

@mixin line-height-loose {
  line-height: 1.75;
}

// TEXT TRANSFORM
@mixin uppercase {
  text-transform: uppercase;
}

@mixin lowercase {
  text-transform: lowercase;
}

// TEXT DECORATION
@mixin underline {
  text-decoration: underline;
}
