@import '@assets/scss/v2/main';
@import '../../../../../assets/scss/shared/breakpoints';

.footer {
  display: flex;
  flex-direction: column;
  padding: 0 2rem 2rem;
  gap: 1rem;

  @include respond(sm) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.buttonWrapper {
  >button {
    justify-content: center;

    >svg {
      display: none;
    }
  }
}

.rejectButton {
  >button {
    color: $blue-dark;
  }
}
