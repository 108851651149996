@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
  padding-top: 0.8rem;
  width: 100%;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}
