@import '@assets/scss/v2/main';

.cartItem {
  padding-top: 2rem;

  &:last-child {
    border-bottom: none;
  }
}

.itemNotification {
  margin-top: 1.5rem;
}

.itemHeader {
  display: flex;
  flex-direction: row;
  position: relative;
}

.itemHeaderMain {
  display: flex;
  flex-direction: column;
  @include textBold;
  @include line-height-thight;
}

.itemHeaderMainText {
  display: flex;
  flex-direction: column;
  max-width: 18rem;
}

.itemSubtitle {
  min-width: 22rem;
  @include textSmall;
}

.itemActions {
  position: absolute;
  right: 0;
  top: 0;
  display: inline-flex;
  align-items: center;
}

.itemAction {
  width: 4.8rem;
}

.itemRemoveButton {
  background-color: $white;
  border: none;
  border-radius: 0.5rem;
  margin: 0 1rem;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  opacity: 0.9;
}

.itemImage {
  display: flex;
  width: 7rem;
  height: auto;
  margin: auto 1.5rem auto 1rem;
  flex-shrink: 0;
}

.itemLabels {
  flex-wrap: wrap;
  display: inline-flex;
  margin-top: 1rem;
  gap: 0.8rem;
}

.itemPaymentInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  padding: 1rem 1.2rem;
  background-color: $grey-alabaster;
}

.itemPaymentInfoItem {
  @include textMedium;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem 0;
}

.itemPaymentInfoSummary {
  @include textBold;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.2rem 0;
}

.stroke {
  text-decoration: line-through;
}

.activePromotion {
  color: $success;
}

.divider {
  margin: 1.6rem 0;
  height: 0px;
  width: 100%;
  border-bottom: 0.5px solid #949494;
}

.outOfStockImage {
  opacity: 0.5;
}

.additionalItemsContainer {
  margin-top: 1.25rem;
  padding: 0rem 1.2rem;
}

.additionalItemHeader {
  display: flex;
  gap: 0.5rem;
  margin: 1rem 0;

  @include textBold;
  @include line-height-thight;
  .link {
    color: $blue-bright;
    cursor: pointer;
  }
}
