@import '@assets/scss/v2/main';

.container {
  display: flex;
  width: 100%;
}

.checkoutSteps {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.checkoutStepName {
  @include textLargeBold;

  width: 100%;
  color: $silver;
  border-top: 2px solid $silver;
  padding: 2.4rem 0;
  text-align: left;
}
