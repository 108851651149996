@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.text {
  @include text;
}
