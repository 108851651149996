@import '@assets/scss/v2/main';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  color: $blue-dark;

  &.bordered {
    border: 1px solid $silver;
    min-height: 65px;
    padding: 1.2rem;
    margin-bottom: 0.8rem;

    &:hover,
    &.checked {
      outline: 3px solid $blue-dark;
    }
  }
}
