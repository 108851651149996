@import '@assets/scss/v2/main';

.infoWrapper {
    @include text-base;
    @include font-weight-bold;
    user-select: none;
}

.info {
    color: $blue-bright;
    user-select: none;
}