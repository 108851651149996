.wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
}

.blogpostHeroImage {
    display: flex;
    justify-content: center;

    max-width: 121.3rem;
    max-height: 40rem;
    height: 100%;
    width: 100%;
    padding: 0 3.2rem;
    
    & img {
        border-radius: 40px;
        width: 100%;
        object-fit: cover;
    }
}