@import '@assets/scss/shared/variables';
@import '@assets/scss/v2/main';

.container {
  width: 100%;
  position: sticky;
  z-index: 5;
  background-color: $white;
  top: 0;
  transition: transform 0.5s ease-in-out;
  border-bottom: 1px solid $silver-light;
}

.visible {
  transform: translate(0);
}

.hidden {
  transform: translate(0, -50rem);
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1.6rem;

  @include respond(m) {
    padding: 2.4rem;
  }
}

.leftNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.logo {
  display: flex;
  align-items: center;
  height: 4.4rem;

  & svg {
    width: 153px;

    @include respond(s) {
      width: 180px;
    }

    @include respond(m) {
      width: 204px;
    }
  }
}

.rightNav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0.75rem;
}

.desktopBackButton {
  display: none;

  @include respond(xl) {
    display: block;
  }
}

.mobileBackButton {
  display: block;

  @include respond(xl) {
    display: none;
  }
}

.iconWithBadgeWrapper {
  position: relative;
}

.localizationDropdown {
  margin-right: 0.8rem;
}

.badge {
  position: absolute;
  @include textExtraSmall;
  color: $white;
  width: 1.65rem;
  height: 1.65rem;
  right: 0;
  top: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loggedUserBadge {
  border-radius: 50%;
  background-color: $blue-bright;
}

.shoppingCartBadge {
  border-radius: 50%;
  background-color: $blue-bright;
}

.sameSizeButton {
  min-width: 4.4rem !important;
  min-height: 4.4rem !important;
  width: 4.4rem !important;
  height: 4.4rem !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.backButton {
  position: relative;
  margin-left: 1.6rem;
  &::after {
    content: '';
    border-width: 2rem 1rem 2rem 0;
    position: absolute;
    left: -1rem;
    top: 50%;
    transform: translateY(-50%);
    border-style: solid;
    border-color: transparent $blue-bright transparent transparent;
    transition: border-color 0.1s, color 0.3s;
    @include respond(m) {
      left: -1.5rem;
      border-width: 2.4rem 1.6rem 2.4rem 0;
    }
  }
}
