@import '@assets/scss/v2/main';

.container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);

  @include respond(m) {
    flex-direction: row;
    max-height: 120px;
  }
}

.item {
  display: flex;
  max-width: 53rem;
  min-width: max-content;
}

.image {
  display: flex;
  max-width: 100px;
  height: auto;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem 2rem auto 2rem;
}

.button {
  @include textBold;
  color: $white;
  height: 4.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: auto;
  margin-bottom: 1.5rem;
  padding: 1.2rem 2.4rem;
  min-width: max-content;
  background: $blue-bright;
  border: 0;
  border-radius: 3px;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }

  &:active {
    background: $blue-dark;
  }
}

.label {
  @include textBold;
}

.text {
  @include textSmall;
}
