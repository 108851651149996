@import '@assets/scss/v2/main';

.brandYellow {
  background-color: $brand-yellow;
}

.brandPink {
  background-color: $brand-pink;
}

.brandGreen {
  background-color: $brand-green;
}

.brandBlue {
  background-color: $blue-bright;
  & > * {
    color: white;
  }
}

.text {
  @include textSmallBold;
  margin: auto 0;
}
