@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: row;
  margin: 1.6rem 0 2.4rem;
  width: 100%;
  max-width: 100%;
  min-width: 33.6rem;
  flex-wrap: wrap;
  gap: 0 1.1rem;
}

.checkboxContainer {
  width: 100%;
  & div {
    grid-gap: 0;
    gap: 0;
  }
}
