@import '@assets/scss/v2/main';

.container {
  width: 100%;
}

.formGroupWrapper {
  margin-bottom: $spacing-m;
}

.inputRow {
  display: flex;
  gap: $spacing-s;
  align-items: flex-start;
}

.changeTextFont {
  display: inline-block;
  font-family: $font-quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 120%;
  color: $blue-bright;
  margin-bottom: $spacing-m;
}

.optionButtonContainer {
  text-align: center;
  text-decoration: underline;
}

.optionButton {
  cursor: pointer;
}

.hintContainer {
  text-align: center;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}
