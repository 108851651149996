@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.button {
  padding-top: 0;
  padding-left: 4.4rem;
  padding-bottom: 1.6rem;
  text-align: left;
  background-color: $transparent-gold;
}

.notificationBar {
  padding-bottom: 0;
}
