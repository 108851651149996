@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.simpleLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 128rem;
  justify-content: center;

  @include respond(xs) {
    max-width: 375px;
    margin: 1.6rem;
  }

  @include respond(s) {
    min-width: 376px;
    max-width: 767px;
    margin: 2.4rem;
  }

  @include respond(m) {
    min-width: 768px;
    max-width: 899px;
    margin: 3.2rem;
  }

  @include respond(l) {
    min-width: 900px;
    max-width: 1199px;
    margin: 2.4rem 4rem;
  }

  @include respond(xl) {
    min-width: 1200px;
    max-width: 1680px;
    margin: 4rem 8rem;
  }

  @include respond(xxl) {
    min-width: 1680px;
    margin: 4rem 8rem;
  }
}
