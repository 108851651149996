@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  background: $white;
  width: 128rem;
  max-width: 100%;
  border-radius: 4px;
  gap: 8rem;
  padding: 4rem 2rem 9.5rem 2rem;
  margin: 2rem 0 -4rem 0;

  @include respond(m) {
    margin: 0 0 3.4rem 0;
    flex-direction: row;
    padding: 6rem 8rem 8.2rem 12rem;
  }
}

.itemsContainer {
  display: flex;
  flex: 2;
  flex-direction: column;
  width: 100%;
  gap: 2.4rem
}

.sideItemsContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}