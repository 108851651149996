@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
  min-width: 28rem;
  max-width: 64rem;
  width: 100%;
}

.accountCardContainer {
  display: flex;
}

.accountCard {
  min-width: 30.4rem;
  max-width: 64rem;
  width: 100%;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
}

.buttonList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 2rem;
  gap: 0.8rem;

  @include respond(l) {
    flex-direction: row;
  }
}

.button {
  width: 100%;

  button {
    width: 100%;
    margin: 0;
  }

  @include respond(l) {
    width: 30rem;
  }
}
