@import '@assets/scss/v2/main';

.titleTextContainer {
  height: 2.8rem;
  margin-right: 3rem;
  margin-left: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleText {
  @include textBold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.modalButtonContainer {
  display: flex;
  max-width: 128rem;
  @include respond(xs) {
    margin: auto 1.6rem;
  }

  @include respond(s) {
    margin: auto 2.4rem;
  }

  @include respond(m) {
    margin: auto 3.2rem;
  }

  @include respond(l) {
    margin: auto 4rem;
  }

  @include respond(xl) {
    margin: auto;
  }

  @include respond(xxl) {
    margin: auto;
  }
}
