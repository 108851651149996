@import '@assets/scss/v2/main';

.container {
  width: 100%;
  margin-top: 1.6rem;
  line-height: 1.4;
}

.textBox {
  margin-bottom: 1.6rem;
}

.link {
  display: inline;
}

.description {
  @include textBold;
}

.feature {
  @include text;
}

.conditionalLink {
  cursor: pointer;
  text-decoration: underline;
  display: inline;
}
