@import '@assets/scss/v2/main';

.label {
    display: flex;
    align-items: center;
    padding: 1.4rem;
    outline: 1px solid $silver;
    box-sizing: border-box;
    border-radius: 4px;
    min-height: 65px;

    &:hover {
        outline: 3px solid $blue-dark;
    }
}

.label input[type="radio"] {
    display: none;
}

.label::before {
    content: "";
    width: 21px;
    height: 21px;
    border: 1px solid $grey-generic;
    border-radius: 50%;
    margin: 0.5rem 1rem 0.5rem 0;
}

.label {
    padding-left: 1rem;
}


.selectedOption {
    outline: 3px solid $blue-dark;
}

.selectedOption.label::before {
    border: 5px solid $blue-bright;
}

.label + .label {
    margin-top: 1.2rem;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}