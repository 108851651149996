@import '@assets/scss/v2/main';

.container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.headerContainerBlack {
  width: 100%;
  color: $black;
}

.headerContainerBlue {
  width: 100%;
  color: $blue-bright;
}

.line {
  border-bottom: 1px solid $grey;
  margin-top: 0.8rem;
}

.textBlack {
  @include textDoubleExtraLargeBold;
  color: $black;
}

.textBlue {
  @include textLargeBold;
  color: $blue-bright;
}
