@import '@assets/scss/v2/main';

.conditionsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;
}

.conditionsHeader {
  display: inline-flex;
  justify-content: space-between;
  height: 4.5rem;
  align-items: center;
}

.conditionHeaderText {
  @include textMediumBold;
}

.link {
  @include textLink;

  cursor: pointer;
}

.conditionsOptionsContainer {
  display: flex;
  flex-direction: column;
}

.conditionsOption {
  display: flex;
  flex-direction: row;
  border: 1px solid $silver;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0.8rem 1.2rem;
  margin-bottom: 1.2rem;
  height: 6rem;
  align-items: center;
  background-color: $white;
  cursor: pointer;

  &:hover {
    border: 3px solid $blue-dark;
    padding: 0.8rem 1rem;
    opacity: 0.8;
  }

  &:disabled {
    color: rgba(128, 128, 128, 0.5);
    background-color: rgba(128, 128, 128, 0.1);
    pointer-events: none;

    .conditionsOptionLabel {
      color: rgba(128, 128, 128, 0.5);
    }
  }
}

.conditionsOptionSelected {
  border: 3px solid $blue-dark;
  padding: 0.8rem 1rem;
}

.conditionsOptionLabel {
  margin: 0 0.8rem;
  @include descriptionLargeBold;
}

.conditionsOptionValue {
  margin: 0 0 0 auto;
}

.checkboxCircle {
  width: 2rem;
  height: 2rem;
  border: 2px solid $grey-tabby;
  border-radius: 50%;
}

.checkboxCircleDisabled {
  border-color: $silver;
}

.checkboxCircleSelected {
  border-color: $black;
  border-width: 0.5rem;
}

.outOfStock {
  @include textMediumBold;
}

.durationsOptionValue {
  @include textMediumBold;

  margin: 0 0 0 auto;
}
