@import '@assets/scss/v2/main';

.cartContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 2.4rem;

  @include respond(m) {
    margin-bottom: 0rem;
  }
}

.topContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  margin-bottom: 3.2rem;
}

.cartButton {
  display: contents;
}

.price {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 1.7rem 0;
}

.priceTitle {
  @include text;
}

.lineThroughPrice {
  text-decoration: line-through;
  color: #878787;
}

.finalPrice {
  font-weight: 700;
}

.wwoColumnText {
  grid-template-columns: 4fr 1fr;
  line-height: 1rem;
}

.smallText {
  @include textSmall;
  font-weight: 500;
}

.greenBold {
  @include textSmallBold;
  color: $green;
}

.pricingContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 3.2rem 0;
}

.pricingMonthlyPayment {
  @include textLargeBold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #d1d5db;
}

.pricingItem {
  @include text;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.planSummary {
  margin-bottom: 3.2rem;
}

.planSummaryText {
  @include textSmall;
  text-align: justify;

  p {
    @include textSmall;
    text-align: justify;
  }
}

.financingDataProcessingInfo {
  margin-bottom: 1.6rem;
}

.financingDataProcessingInfoText {
  @include textExtraSmall;
  text-align: justify;

  p {
    @include textExtraSmall;
    text-align: justify;
  }
}
