@import '@assets/scss/v2/main';

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  border-radius: 0.4rem;
  margin-top: 1.5rem;
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
}

.containerV2 {
  margin-top: -2.4rem !important;

  @include respond(xl) {
    margin-top: 0 !important;
  }
}

.containerActive {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.body {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 1rem;
  width: 100%;
  padding: 0 0 0.2rem 3rem;
}

.checkbox {
  display: flex;
  width: 2rem;
  height: 2rem;
  border: 2px solid $grey-tabby;
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 1rem;
  justify-content: center;
  align-items: center;
  background-color: $white;
}

.checkboxChecked {
  background-color: $blue-bright;
  border: 2px solid $blue-bright;
  padding: 0.2rem;
}

.buttonContainer {
  display: flex;
  width: 12rem;
  min-width: 12rem;
  max-width: 12rem;
  height: 5rem;
  min-height: 5rem;
  max-height: 5rem;
}

.textButton {
  @include textMedium;

  background-color: transparent;
  border: none;
  cursor: pointer;
}

.inputContainer {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.input {
  border: 2px solid $silver;
  box-sizing: border-box;
  border-radius: 0.4rem;
  background-color: $white;
  height: 4.5rem;
  width: 100%;
  padding: 0 1rem;
  font-weight: 700;
  color: $black;

  &:focus {
    border-color: $grey-tabby;
    outline: none;
  }
}

.inputIcon {
  position: absolute;
  right: 1.5rem;
  height: auto;
  width: 1.6rem;
}

.inputValid {
  padding-right: 4rem;
  border-color: $success;
}

.inputError {
  border-color: $contextual-error;
  padding-right: 4rem;
}

.inputDisabled {
  &:hover {
    cursor: not-allowed;
  }
}

.inputErrorMessageContainer {
  display: inline-flex;
  margin: 0.1rem auto 0 4.3rem;
  min-height: 3rem;
}

.promoErrorMessageIcon {
  height: 1.6rem;
  width: auto;
}

.promoErrorMessage {
  @include inputHint;

  margin: auto 0;
  font-size: 1.5rem;
  color: $contextual-error;
  margin-left: 0.2rem;
  max-width: 18rem;
}

.codeText {
  display: inline-flex;
  width: 100%;
  margin: 1.5rem 0 0 6rem;
  gap: 1rem;
  align-items: center;
}

.removeButton {
  width: 3.5rem;
}

.removeCodeButtonIcon {
  width: auto;
  height: 2.6rem;
}

.checkButton {
  @include textMedium;

  background-color: $blue-bright;
  color: $white;
  border-radius: 3px;
  border: 0;
  padding: 1rem 3rem;
  height: 4.5rem;
  margin: 0 1rem;
  cursor: pointer;
}

.hidden {
  display: none;
}

.promoCodeForm {
  width: 100%;

  > * {
    width: 100%;
  }
}

.checkboxContainer {
  padding: 1.2rem 0 0 3.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  align-items: top;
  width: 100%;
}
