@import '@assets/scss/v2/main';
.container {
  width: 100%;
  min-height: 70rem;
}

.radioLabel {
  display: flex;
  align-items: center;
}

.radioLabelTextContainer {
  margin-left: 0.85rem;
  display: flex;
  flex-direction: column;
}

.radioLabelLightText {
  @include font-sans;
  @include font-weight-normal;
  @include text-sm;
  @include line-height-loose;
}

.radioChildrenSeperator {
  width: calc(100% + 2.4rem);
  margin-top: 1.6rem;
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  margin-bottom: 1rem;
}

.creditCardRow {
  display: flex;
  justify-content: space-between;
}

.creditCardColumn {
  width: 46%;
}

.recurringConsentCheckbox {
  width: 100%;
}

.inputContainer {
  margin-bottom: 1.6rem;
}

.radioLabelNameText {
  @include textBold;
}

.changeAlreadyDefinedPaymentContainer {
  margin-bottom: 2rem;
  width: 100%;
}

.recurringConsentRichText {
  display: inline-block;

  * {
    display: inline;
  }

  a {
    font-family: $font-quicksand;
    font-weight: 700;
  }
}
.takeoverProceedButton {
  margin-top: 2.4rem;
  margin-bottom: 9rem;
  width: 100%;

  button {
    width: 100%;
  }
}
.loaderContainer {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  height: 5rem;
  width: 100%;
}
