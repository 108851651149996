@import '@assets/scss/v2/main';

.actionIcon {
    @include respond(xl) {
        display: none;
    }
}

.sameSizeButton {
    min-width: 4.4rem !important;
    min-height: 4.4rem !important;
    width: 4.4rem !important;
    height: 4.4rem !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}