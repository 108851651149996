@import '@assets/scss/v2/main';

.container {
  background-color: $blue-medium-bright;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.contentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: $blue-medium-bright;
  padding: 1.8rem 1.8rem 0;
}

.backgroundContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 86.2rem;
  background-repeat: no-repeat;
  background-position: top;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10rem;
}
