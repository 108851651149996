@import '@assets/scss/v2/main';

$scale: 0.75;

.freshChatWidget {
  bottom: 0 !important;
  right: 0 !important;
  transform: scale($scale);
  z-index: 9998 !important;

  &.bouncingAnimation {
    animation-name: bounce;
    animation-timing-function: ease;
    animation-duration: 2s;
    animation-iteration-count: 3;
  }

  &.userBar {
    bottom: 50px !important;

    @include respond(md) {
      bottom: 0px !important;
    }
  }
}

.freshChatWidgetOpen {
  transform: unset !important;

  &.bouncingAnimation {
    animation-name: unset;
    animation-timing-function: ease;
    animation-duration: 2s;
    animation-iteration-count: 3;
  }
}

@keyframes bounce {
  0% {
    transform: scale($scale, $scale) translateY(0);
  }

  10% {
    transform: scale(0.8, 0.555) translateY(5px);
  }

  30% {
    transform: scale(0.555, 0.8) translateY(-30px);
  }

  50% {
    transform: scale($scale, $scale) translateY(0);
  }

  100% {
    transform: scale($scale, $scale) translateY(0);
  }
}
