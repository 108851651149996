@import '@assets/scss/v2/main';

.container {
  margin: 0 auto;
  max-width: 128rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 2.4rem;
  flex-direction: column-reverse;
  background: transparent;

  @include respond(m) {
    padding: 3.2rem 3.2rem;
    background: transparent;
    flex-direction: row;
  }

  @include respond(l) {
    padding: 3.2rem 8rem;
    flex-direction: row;
  }
}

.imageLeft {
  @include respond(m) {
    flex-direction: row-reverse;
  }
}

.content {
  justify-content: center;
  align-items: center;
  flex: 1;
  padding-top: 1.6rem;

  @include respond(m) {
    margin-right: 3.2rem;
    background: transparent;
    flex-direction: row;

    .imageLeft & {
      margin-right: 0;
      margin-left: 3.2rem;
    }
  }

  @include respond(l) {
    padding-left: 5.6rem;
    margin-right: 8rem;

    .imageLeft & {
      margin-left: 0;
    }
  }
}

.contentImage {
  position: relative;
  flex: 1;
  width: 100%;
}

.image {
  display: flex;
  background: transparent;
}

.sticker {
  width: 10rem;
  height: 10rem;
  background-color: $brand-yellow;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: none;
  text-align: center;
  transform: matrix(0.98, 0.19, -0.19, 0.98, 0, 0);

  @include textExtraSmallBold;

  @include respond(m) {
    display: flex;
    padding: 2rem;
    width: 12rem;
    height: 12rem;
  }

  &.left {
    position: absolute;
    right: 55%;
    top: 65%;
  }

  &.right {
    position: absolute;
    left: 70%;
    top: 40%;

    @include respond(s) {
      left: 55%;
      top: 65%;
    }
  }
}

.contentContainerBackgroundBlueBright {
  background-color: $blue-bright;

  *:not([data-button]) {
    color: $white;
  }
}

.contentContainerBackgroundBlueDark {
  background-color: $blue-dark;

  *:not([data-button]) {
    color: $white;
  }
}

.contentContainerBackgroundBlueLight {
  background-color: $blue-light;
}

.contentContainerBackgroundBrandYellow {
  background-color: $brand-yellow;
}

.contentContainerBackgroundBrandPink {
  background-color: $brand-pink;
}

.contentContainerBackgroundBrandGreen {
  background-color: $brand-green;
}
