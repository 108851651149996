@import '@assets/scss/v2/main';

.container {
  width: 100%;
  max-width: 112rem;
  margin: 0 auto;
}

.showButton {
  @include textSmallBold;

  color: $black;
  margin-top: 2rem;
  border: none;
  padding: 1rem;
  cursor: pointer;
  width: 100%;
  text-align: center;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  height: 4.7rem;
}
