@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.dayContainer {
  display: flex;
  flex-direction: column;
}
.weekDay {
  @include textSmall;
}

.dayWithMonth {
  @include textBold;
}

.hours {
  @include textBold;
}

.activeButton {
  border: 2px solid $blue-bright;
}

.title {
  margin-left: 0.8rem;
  @include textLargeBold;
}

.titleContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1.6rem;
  margin-top: 1.6rem;
}

.readMore {
  margin: 0 auto 0;
  position: relative;
  &::before,
  &::after {
    width: 100%;
    content: '';
    height: 1px;
    background-color: $blue-dark;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.2;
  }

  &::before {
    right: 100%;
    margin-right: 10px;
  }

  &::after {
    left: 100%;
    margin-left: 10px;
  }
}

.separator {
  margin-bottom: 1.6rem;
}

.notificationContainer {
  margin: 0 0 4rem;
  width: 100%;
}

.emptyNotificationContainer {
  margin: 0 0 4rem;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}

.button {
  height: 5rem;
}

.chooseDateContainer {
  margin-top: 1.6rem;
}
