@import '@assets/scss/v2/main';

.headerTitle {
  @include headingSubtitleBold;
}

.headerSubtitle {
  @include textMedium;
}

.energyLabel {
  @include textLink;
  margin-top: 1rem;
}

.mobileOnly {
  display: block;
  padding: 0 1.6rem;
  margin: 2.4rem auto 0;

  @include respond(s) {
    padding: 0 2.4rem;
  }

  @include respond(m) {
    padding: 0 3.2rem;
  }

  @include respond(l) {
    display: none;
  }
}
