@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  max-width: 46rem;
  color: $black;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
}

.pricesContainer {
  display: flex;
  flex-direction: column;
}

.headerSubtitle {
  font-family: $font-quicksand;
  font-weight: 500;
}

.energyLabel {
  margin-top: 1rem;
}

.description {
  margin: 1rem 0;
}

.main {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.offerCards {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.monthlyFeeContainerText {
  @include textMediumBold;
}

.monthlyFeeContainerValue {
  @include textMediumBold;
}

.deliveryFeeContainer {
  display: inline-flex;
  justify-content: space-between;
  border-bottom: 1px solid $disabled;
  height: 4rem;
  align-items: center;
}

.monthlyFeeContainer {
  display: inline-flex;
  justify-content: space-between;
  height: 4rem;
  align-items: center;
}

.deliveryFeeContainerText {
  @include textMedium;
}

.deliveryFeeContainerValue {
  @include text;
}

.paymentsContainer {
  margin: 0 auto;
  width: max-content;
}

.headerTitle {
  @include headline;
}

.headerSubtitle {
  @include textMedium;
}

.headingTitle {
  @include headingMediumTitle;
}

.notificationContainer {
  margin-top: 0.8rem;
}

.deliveryTimeContainer {
  margin: 0.9rem 0;
}

.outOfStockForm {
  margin-bottom: 3rem;
}

.desktopOnly {
  display: none;

  @include respond(l) {
    display: block;
  }
}

.closeButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
  font-weight: 700;
}

.closeButton {
  display: block;
  margin-top: 1rem;
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;

  > svg circle {
    display: none;
  }
}
