@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.codeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 8px 16px 12px;
  gap: 8px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}

.socialMediaContainer {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 500px;
  padding-top: 0.8rem;
}

.icon {
  border-radius: 50%;
  width: 54px;
  height: 54px;
  margin: 0 1rem;
}

.title {
  @include textExtraLargeMediumBold;
}

.shareCodeContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.shareCodeTitle {
  margin: 0 auto;
  @include textMediumBold;
}

.shareCode {
  margin: 0 auto;
  @include textDoubleExtraLargeBold;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}
