@import '@assets/scss/v2/main';

$checkboxSize: 2rem;

.container {
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  margin-bottom: 0.8rem;
  border-radius: 4px;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  color: $black;
  user-select: none;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.grayLabel {
  background-color: $grey-alabaster;
  box-shadow: none;
}

.whiteLabel {
  background-color: $white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}

.whiteNoShadowLabel {
  background-color: $white;
}

.labelText {
  width: 100%;
  color: $black;
}

.externalText {
  @include text;

  margin-top: 2.1rem;
  font-weight: 400;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: $checkboxSize;
  max-height: $checkboxSize;
  height: $checkboxSize;
  min-width: $checkboxSize;
  max-width: $checkboxSize;
  width: $checkboxSize;
  border: 2px solid $black;
  border-radius: 2px;
  background-color: $white;
}

.selected {
  background-color: $blue-bright;
  border: none;
}

.disabled {
  opacity: 0.2;
}

.labelContainer {
  border-radius: 4px;

  input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ .checkmark {
    background-color: $white;
  }

  input:checked ~ .checkmark {
    background-color: $blue-bright;
    border: none;
  }

  input:checked ~ .checkmark:after {
    content: '';
  }
}

.labelText {
  @include textMedium;
}

.checkmark {
  position: relative;
  top: 0;
  left: 0;
  background-color: $white;
  border: 2px solid $black;
  border-radius: 2px;
  color: $black;
  margin-right: 1.2rem;
  min-height: $checkboxSize;
  max-height: $checkboxSize;
  height: $checkboxSize;
  min-width: $checkboxSize;
  max-width: $checkboxSize;
  width: $checkboxSize;
}

.checkmark:after {
  position: absolute;
  left: 6px;
  top: 1px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.errorMessage {
  @include textMedium;

  display: flex;
  flex-direction: row;
  margin: 0.4rem 0 0 1rem;
  color: $error;
  & svg {
    margin-right: 1.6rem;
  }
}
