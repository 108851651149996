@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
  flex: 1;

  @include respond(m) {
    flex-direction: row;
  }

  .desktop {
    display: none;

    @include respond(m) {
      display: flex;
    }
  }
}

.titleSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 7.2rem 3.2rem 2.4rem;
  justify-content: flex-end;
  color: $black;
  text-align: left;
}

.title {
  @include textExtraLargeBold;

  margin-bottom: 0.8rem;
  text-align: left;
}

.productsSection {
  display: flex;
  flex-direction: row;
  flex: 1;

  &.mobile {
    display: flex;

    @include respond(m) {
      display: none;
    }
  }
}

.description {
  @include text;
}
