@import '@assets/scss/shared/variables';

.container {
  display: inline-block;
  position: relative;
  height: 50%;
  max-height: 80px;
  aspect-ratio: 1;
}

.pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
