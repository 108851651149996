@import '@assets/scss/v2/main';

.container {
  display: flex;
  justify-content: center;
}

.blocks {
  display: flex;
  flex-direction: row;
  max-width: 880px;
  height: 2.4rem;
  justify-content: space-between;
  align-items: center;
  gap: 2.4rem;
}
