@import '@assets/scss/v2/main';

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.block {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.animatedBlock {
  animation: fade 1000ms;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 1.2rem;
}

.text {
  @include textMediumBold;

  display: flex;
  align-items: center;
  flex-direction: row;
  color: $black;
}
