.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  border-bottom: 1px solid #d9d9d9;
}

.title {
  display: flex;
  align-items: center;
}

.boldTitleText {
  font-weight: 700;
}

.closeButtonWrapper {
  display: flex;
  justify-content: flex-end;
}

.closeButton {
  width: 2.1rem !important;
  height: 2.1rem !important;
  min-width: 2.1rem !important;
  min-height: 2.1rem !important;
  padding: 1.6rem;
  margin: 0;
}
