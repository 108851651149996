@import '@assets/scss/v2/main';

.logoContainer {
  text-align: center;
  width: 100%;
  margin-top: 20rem;
}

.waitingText {
  text-align: center;
  margin: 3.2rem 2.4rem;
}

.retryRichtext {
  margin-bottom: 3.2rem;
  margin-top: 4rem;
}

.noMargin {
  margin-top: 0;
}
