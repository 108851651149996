@import '@assets/scss/v2/main';

.container {
  min-height: 11rem;
  padding: 3.3rem 1rem 3.3rem 4rem;
  background: $white;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  width: calc(100vw - 6rem);

  @include respond(l) {
    width: 36.2rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 1rem;
}

.cardIcon {
  height: 2.4rem;
  width: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  @include text-lg;
  @include font-weight-bold;
  @include line-height-none;
  color: $black;
}

.bigTitle {
  @include text-2xl;
}

.smallText {
  @include line-height-relaxed;
  @include text-sm;
  @include font-weight-bold;
  color: rgba(0, 0, 0, 0.5);
  opacity: 0.9;
}

.header {
  line-height: 38px;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 12px;
}

.underline {
  text-decoration: underline;
}

.visibleOnDesktop {
  display: none;

  @include respond(xl) {
    display: initial;
  }
}

.visibleOnMobile {
  @include respond(xl) {
    display: none;
  }
}

.subtitle {
  opacity: 0.9;
  @include text-base;
  @include font-weight-medium;
  @include line-height-thight;
}
