@import '@assets/scss/v2/main';

.icon {
  width: 24px;
  height: 24px;
  border: 1px solid $base-grey;
  border-radius: 50%;
}

.dropdownButton {
  display: flex;
  align-items: center;
  gap: .8rem;
}

.darkButton {
  padding-right: 0;

  &:hover {
    background: none;
    color: $white;
  }
}


.localizationChangeButton {
  padding: 1.2rem 1.6rem;
}