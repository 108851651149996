@import '@assets/scss/v2/main';

.container {
  display: none;
  position: fixed;
  bottom: 1rem;
  background-color: white;
  width: 100%;
  z-index: 3;
  @include respond(m) {
    display: block;
  }
}
