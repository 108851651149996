@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex: 1 1 auto;
  min-width: 22rem;
  max-width: 38rem;
  height: 100%;
}

.fixedHeight {
  height: 27.9rem;
}

.informationCard {
  display: flex;
  flex-direction: column;
  padding: 3.2rem 2.4rem 3.2rem 2.4rem;
  background-color: $grey-alabaster;
  box-shadow: 0px 1px 4px rgba(0, 1, 4, 0.2);
  border-radius: 4px;
  height: 100%;
  justify-content: space-between;
}

.informationCardContent {
  display: flex;
  flex-direction: column;
  gap:1.6rem;
}

.informationCardContentGreyCenterText {
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
}

.rotationLeft {
  margin: 0.2rem;
  transform: rotate(2deg);
}

.rotationRight {
  transform: rotate(-2deg);
}

.arrow {
  margin-top: 1.6rem;
}

.icon {
  max-height: 4.8rem;
  height: 4.8rem;
}

.iconGreyCenterText {
  display: flex;
  align-items: center;
  height: 100%;
}

.imageGreyCenterText {
  img {
    display: block;
    height: 7rem;
    min-height: 7rem;
    width: 7rem;
    min-width: 7rem;
  }
}

.header {
  @include textDoubleExtraLargeBold;
  color: $blue-dark;
}

.headerGreyCenterText {
  @include textExtraLargeBold;

  display: flex;
  align-items: center;
  margin-top: 0;
  color: $blue-dark;
}

.content {
  @include text;
  color: $blue-dark;
  flex: 1;
}

.contentGreyCenterText {
  display: none;
}

.button {
  @include textSmall;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  opacity: 0.6;
  margin-top: 1.6rem;
  color: $blue-dark;
}

.link {
  width: 100%;
  cursor: pointer;
}

.informationCardBackgroundGrey {
  .informationCard {
    background-color: $grey-alabaster;

    .informationCardContent * {
      color: $blue-dark;
      
      svg {
        fill: $blue-dark;
      }
    }

  }
}

.informationCardBackgroundGreyCenterText {
  .informationCard {
    background-color: $grey-alabaster;

    .informationCardContent * {
      color: $blue-dark;
      
      svg {
        fill: $blue-dark;
      }
    }

  }
}

.informationCardBackgroundBlueBright {
  .informationCard {
    background-color: $blue-bright;

    .informationCardContent * {
      color: $white;

      svg {
        fill: $white;
      }
    }

  }
}

.informationCardBackgroundBlueDark {
  .informationCard {
    background-color: $blue-dark;

    .informationCardContent * {
      color: $white;
      
      svg {
        fill: $white;
      }
    }

  }
}

.informationCardBackgroundBlueLight {
  .informationCard {
    background-color: $blue-light;

    .informationCardContent * {
      color: $blue-dark;
      
      svg {
        fill: $blue-dark;
      }
    }

  }
}

.informationCardBackgroundBrandYellow {
  .informationCard {
    background-color: $brand-yellow;

    .informationCardContent * {
      color: $blue-dark;
      
      svg {
        fill: $blue-dark;
      }
    }

  }
}

.informationCardBackgroundBrandPink {
  .informationCard {
    background-color: $brand-pink;

    .informationCardContent * {
      color: $blue-dark;
      
      svg {
        fill: $blue-dark;
      }
    }

  }
}

.informationCardBackgroundBrandGreen {
  .informationCard {
    background-color: $brand-green;

    .informationCardContent * {
      color: $blue-dark;
      
      svg {
        fill: $blue-dark;
      }
    }

  }
}
