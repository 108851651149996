@import '@assets/scss/v2/main';

.container {
  display: flex;
  width: 100%;
  margin: 1rem 0;
  justify-content: center;
}

.scrollNavigation {
  width: unset !important;
  padding: 2rem 2.4rem;
  gap: 1.4rem;
  overflow-y: hidden;
  flex: 1;
}

.noScrollNavigation {
  padding: 0 2.4rem 2rem;
  gap: 2.4rem;
  overflow-y: hidden;
  overflow-x: hidden;
  flex: 1;

  & [class*="OrderCardComponent_wrapper"] {
    width: 100%;
    min-width: unset;
    max-width: unset;
  }
}

.itemContainer {
  scroll-snap-align: center;
}

.item {
  height: 100%;
}
