@import '@assets/scss/v2/main';

.product {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 0.4rem 1.6rem 0.4rem;
  color: $black;

  @include respond(m) {
    margin-bottom: 1.6rem;
    padding: 0 7.2rem 0 0;
  }
}

.imageContainer {
  text-align: left;
}

.image {
  display: flex;
  margin-bottom: 1.6rem;
}

.title {
  @include textExtraLargeBold;

  margin-bottom: 0.5rem;
}

.moreImagesText {
  margin-top: 0.4rem;
}

.type {
  margin-top: 1.2rem;
}

.textLink {
  @include textLink;
}
