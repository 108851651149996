.container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  z-index: 4;

  iframe {
    display: block;
    width: 100%;
  }
}
