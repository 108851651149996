@import '@assets/scss/v2/main';

.paymentSummary {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
}

.paymentSummaryTotal {
  @include textLargeBold;

  padding: 1.2rem 0;
  border-bottom: 2px solid $black;
}

.itemText {
  @include textLargeBold;
}

.paymentSummaryItem {
  display: inline-flex;
  justify-content: space-between;
  padding: 1.2rem 0;
}

.stroke {
  text-decoration: line-through;
}

.activePromotionSummaryItem {
  gap: 4rem;
}

.activePromotionText {
  color: $success !important;
}

.text {
  @include textBold;
}