@import '@assets/scss/v2/main';

.scrollNavigation {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  align-self: center;
  width: 100%;
  z-index: 3;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.scroll {
  scrollbar-color: $brand-pink $white;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background-color: $white;
    border-radius: 16px;
    height: 0.36rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $blue-bright;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-track-piece {
    border-radius: 16px;
    background-color: $white;
  }

  &::-webkit-scrollbar-button { 
    background-color: $blue-solitude;
    width: 0%;
  }

  &::-webkit-scrollbar-button:end:increment {
    width: 2.4rem;
    display: block;
    background-color: $blue-solitude; 
  }

  &::-webkit-scrollbar-button:end:decrement {
    display: none;
  }

  &::-webkit-scrollbar-button:start:decrement {
    width: 2.4rem;
    display: block;
    background-color: $blue-solitude; 
  }

  &::-webkit-scrollbar-button:start:increment {
    display: none;
  }
}

.hideScroll {
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

.mobile {
  @include respond(l) {
    display: none;
  }
}
