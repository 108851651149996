@import '@assets/scss/v2/main';

.labelContainer {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
}

.machineCodeContainer {
  @include text;
}

.labelContent {
  gap: 0.5rem;
  display: flex;
}

.pricingLabel {
  @include textDoubleExtraSmallBold;
}
