@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.2rem;
  min-width: 30.4rem;
  width: 100%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  padding: 1.6rem;
  background-color: $white;
}

.accountCardContainer {
  display: flex;
}

.accountCard {
  min-width: 30.4rem;
  max-width: 30.4rem;
  width: 30.4rem;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
}

.headerLeft {
  display: flex;
}

.imageContainer {
  width: 10rem;
}

.headerRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.4rem;
}

.statusContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $white-smoke;
  padding: 1.2rem;
}

.processDateContainer {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
}

.processDateText {
  @include textExtraSmallBold;
}

.processDate {
  @include textExtraSmall;
}

.termsText {
  @include textExtraSmallBold;
  font-size: 1.6rem;
}

.statusText {
  @include textExtraSmallBold;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  color: $black;
}

.applianceName {
  @include textBold;
}

.articleNumber {
  @include text;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  line-height: 2.4rem;
}

.applianceCondition {
  @include textDoubleExtraSmallBold;
  color: $black;
}

.calendarIcon {
  display: block;
  width: 1.6rem;
  height: 1.6rem;
}

.price {
  @include textSmallBold;
  color: $blue-bright;
  text-align: end;
  font-size: 1.6rem;
}

.discountIsActive {
  @include textDoubleExtraSmallBold;
  color: $black;
}

.dateRangeContainer {
  @include textExtraSmall;
  color: $black;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.3rem;
}

.cancellationDateContainer {
  @include textExtraSmall;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.3rem;
}

.button {
  * {
    margin: 0;

    a {
      width: 100%;
    }

    button {
      width: 100%;
      margin: 0;
    }
  }
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  margin-top: 1rem;
}
