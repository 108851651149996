@import '@assets/scss/v2/main';

.container {
  display: flex;
  width: 100%;
  padding: 0;
}

.checkoutRegisterForm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loginLink {
  user-select: none;
  text-decoration: underline;
  cursor: pointer;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  white-space: nowrap;

  @include respond(xs) {
    flex-direction: column;
  }

  @include respond(s) {
    flex-direction: row;
  }
}

.column {
  display: flex;
  flex-direction: column;
  color: $black;
  gap: 0.8rem;
  margin: 2.4rem 0 0;
}

.optionButtonContainer {
  text-align: left;
  text-decoration: underline;
}

.optionButton {
  cursor: pointer;
}

.hintContainer {
  text-align: center;
}

.notificationBarWrapper {
  margin-bottom: 2.4rem;
}

.columnText {
  @include textExtraLargeBold;
}

.columnDescriptionText {
  @include text;
}

.optionButtonText {
  @include text;
}
