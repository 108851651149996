@import '@assets/scss/v2/main';

.durationsContainer {
  display: flex;
  flex-direction: column;
}

.durationsHeader {
  display: inline-flex;
  justify-content: space-between;
  height: 4.5rem;
  align-items: center;
}

.durationHeaderText {
  @include textMediumBold;
}

.durationsOptionsContainer {
  display: flex;
  flex-direction: column;
}

.durationsOption {
  display: flex;
  flex-direction: row;
  border: 1px solid $disabled;
  box-sizing: border-box;
  border-radius: 0.4rem;
  padding: 0.8rem 1.2rem;
  margin-bottom: 0.8rem;
  height: 6rem;
  align-items: center;
  background-color: $white;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    border: 3px solid $blue-dark;
    padding: 0.8rem 1rem;
    opacity: 1;
  }
}

.durationsOptionSelected {
  border: 3px solid $blue-dark;
  padding: 0.8rem 1rem;
  opacity: 1;
}

.durationsOptionLabel {
  @include textMediumBold;

  margin: 0 0.8rem;
}

.durationsOptionValue {
  @include textMediumBold;

  margin: 0 0 0 auto;
}

.checkboxCircle {
  width: 2rem;
  height: 2rem;
  border: 2px solid $grey-tabby;
  border-radius: 50%;
}

.checkboxCircleDisabled {
  border-color: $silver;
}

.checkboxCircleSelected {
  border-color: $black;
  border-width: 0.5rem;
}

.link {
  @include textLink;

  cursor: pointer;
}
