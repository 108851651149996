@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.1rem;
  width: 100%;
  max-width: 100%;
  min-width: 33.6rem;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  padding: 1.6rem 1.2rem;
  background-color: $white;
}

.iconContainer {
  width: 1.8rem;
  height: 1.8rem;
}

.creditContainer {
  display: flex;
  flex: 8;
  flex-direction: column;
  align-items: flex-start;
}

.creditIcon {
  width: 1.8rem;
  height: 1.8rem;
}

.creditCard {
  @include text;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.topMargin {
  margin-top: 1.6rem;
}

.bold {
  @include textBold;
  line-height: 1;
}

.editText {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  @include textExtraSmallBold;
  @include underline;

  cursor: pointer;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}

.noPaymentMethodText {
  padding-left: 2rem;
}
