@import '@assets/scss/v2/main';

.container {
  width: 100%;
  display: flex;
}

.deliveryAddress {
  width: 100%;
  padding-top: 2rem;
}

.deliveryAddressTitle {
  @include textBold;
}

.leftColumn {
  width: calc(100% - 3.3rem);
}

.editText {
  @include textExtraSmallBold;

  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}

.emailElipsis {
  width: calc(100% - 3.3rem);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.userDetails {
  width: 100%;
}
