@import '@assets/scss/v2/main';

.formContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  gap: 2rem;

  @include respond(s) {
    max-width: 50rem;
  }
}

.primaryButton button{
  transition: all 0.3s ease-in-out;
}
