@import '@assets/scss/v2/main';

.container {
  margin: 1.6rem 0 2.4rem;
  width: 100%;
  max-width: 100%;
  min-width: 33.6rem;
  flex-wrap: wrap;
  gap: 0 1.1rem;
}
