@import '@assets/scss/v2/main';

.title {
  position: absolute;
  top: 11.3rem;
  width: 100%;

  @include respond(m) {
    top: 14.9rem;
  }

  @include respond(xl) {
    top: 10.4rem;
  }
}
