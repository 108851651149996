@import '@assets/scss/v2/main';

.container {
  display: block;
  min-width: 33.6rem;
  padding: 1.6rem;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: $white;
  border-radius: 0.4rem;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.6rem;
}

.headerLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
}

.headerRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
}

.dateIcon {
  height: 1.6rem;
  width: 1.6rem;
}

.dateText {
  @include textBold;

  color: $black;
}

.amountText {
  @include textSmallBold;

  color: $blue-bright;
}

.downloadText {
  @include textExtraSmall;
  @include underline;

  cursor: pointer;
  color: $black;
}

.label {
  @include textExtraSmallBold;

  padding: 1.2rem 0.4rem;
}

.actionButtons {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 0.6rem;
}

.button {
  * {
    margin: 0;

    a {
      width: 100%;
    }

    button {
      width: 100%;
      margin: 0;
    }
  }
}
