@import '@assets/scss/v2/main';

.mobileFooter {
  display: block;

  @include respond(m) {
    display: none;
  }
}

.desktopFooter {
  display: none;

  @include respond(m) {
    display: block;
  }
}

.footer {
  display: flex;
  position: relative;
  flex-direction: column;
  // align-items: center;
  padding: 4rem 0.5rem 2rem 0.5rem;
  margin-top: auto;
  font-size: 16px;
  color: $white;
  background: $erie-black;

  @include respond(m) {
    padding: 4rem 2rem 3rem 2rem;
  }

  .top {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 4rem;

    @include respond(m) {
      margin-bottom: 14rem;
      flex-direction: row;
    }

    .left {
      display: flex;
      flex-direction: column;
      flex: 1;

      @include respond(m) {
        margin-right: 4rem;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      flex: 2;
      justify-content: space-between;

      @include respond(m) {
        flex-wrap: wrap;
        max-height: 500px;
      }

      .column {
        display: flex;
        flex-direction: column;
        margin-right: 4rem;
        flex: 1;
        border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
        width: 100%;

        &:last-child {
          border-bottom: 0;
        }

        @include respond(m) {
          width: max-content;
          margin-bottom: 0;
          border-bottom: 0;
        }

        .heading {
          display: inline-flex;
          padding: 1.5rem 0;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }

          @include respond(m) {
            padding: 0;
            margin-bottom: 3rem;

            &:hover {
              text-decoration: initial;
              cursor: initial;
            }
          }

          .expand {
            margin-left: auto;

            @include respond(m) {
              display: none;
            }
          }
        }

        .list {
          list-style-type: none;
          display: none;

          @include respond(m) {
            display: flex;
            flex-direction: column;
            margin-bottom: 3rem;
          }

          li:nth-child(n + 1) {
            margin-bottom: 1rem;

            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }

          &--expanded {
            @extend .list;
            display: flex;
            flex-direction: column;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    width: 100%;

    @include respond(m) {
      flex-direction: row;
    }

    .left {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      padding-top: 2rem;

      @include respond(m) {
        padding-top: 0;
      }

      .item {
        line-height: 2;
        margin-right: 3rem;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .right {
      list-style-type: none;
      display: flex;
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
      padding: 2rem 0;
      align-items: flex-start;

      @include respond(m) {
        padding-top: 0;
        border-bottom: 0;
      }

      .item {
        position: relative;
        display: inline-flex;
        line-height: 2;
        text-decoration: underline;
        margin-right: 1.5rem;
        align-items: center;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }

        @include respond(m) {
          margin-right: 0;
          margin-left: 1.5rem;
        }
        .background {
          display: none;
          overflow: hidden;
        }

        .background-visible {
          position: fixed;
          display: block;
          top: 0;
          left: 0;
          min-width: 100vw;
          min-height: 100vh;
          background: rgba(0, 0, 0, 0.7);
          transition: opacity 350ms;
          z-index: 10;
        }

        .icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
.backToTop {
  display: flex;
  align-items: center;
  .button {
    width: 4.8rem;
    margin-right: 0.5rem;
  }
}

.divider {
  @include respond(m) {
    margin: 3rem 0 2.5rem 0;
    height: 0px;
    width: 100%;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
  }
}

.info-links {
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
  width: 230px;
  color: $white;
  min-width: 100%;
  list-style-type: none;

  @include respond(m) {
    min-width: 0;
  }

  .item-container {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    &:last-child {
      border-bottom: 0;
    }
  }

  .item {
    display: flex;
    flex-direction: row;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .icon {
      margin-right: 1rem;
      width: 3rem;
      line-height: 2rem;
    }

    .body {
      display: flex;
      flex-direction: column;

      span:nth-child(n + 2) {
        color: $color-tertiary-grey;
      }
    }
  }
}

.bodyTitle {
  @include copyLead;

  color: unset;
}

.bodySubtitle {
  @include textExtraSmall;

  color: unset;
}

.navigationLabel {
  @include navigationFooter;

  color: unset;
}

.groupTitleText {
  @include subheading;

  color: unset;
}

.contactMethodTitle {
  @include subheading;

  color: unset;
}

.item {
  @include textSmall;

  color: unset;
}

.headingTitle {
  @include title;

  color: unset;
}

.footerSimpleLayout {
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
