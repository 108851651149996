@import '@assets/scss/v2/main';
@import '@assets/scss/v2/typography';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
  justify-content: space-between;
}

.rowLine {
  border: none;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  height: 1px;
  background-color: $black;
}

.normalPriceAboveDiscount {
  text-decoration: line-through;
}

.rowLeftColumn {
  @include textLargeBold;
}

.rowRightColumn {
  @include textExtraLargeBold;
}

.discountedPrice {
  color: $success;
}

.rowLeftColumnDiscountMessage {
  @include textLarge;
}
