@import '@assets/scss/v2/main';

.titleContainer {
  width: 100%;
  @include textExtraLargeMediumBold;
}

.text {
  @include textExtraSmall;
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
}
