@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.overflowLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;

  @include respond(xs) {
    margin: 1.6rem 0;
  }

  @include respond(s) {
    margin: 2.4rem 0;
  }

  @include respond(m) {
    margin: 3.2rem 0;
  }

  @include respond(l) {
    margin: 2.4rem 0;
  }

  @include respond(xl) {
    margin: 4rem 0;
  }

  @include respond(xxl) {
    margin: 4rem 0;
  }
}
