@import '@assets/scss/v2/main';

.container {
  width: 100%;
}

.card {
  display: flex;
  padding: 1.6rem 1.2rem;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: $white;
}

.cardIcon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 1.3rem;
}

.loginForm {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 0.8rem;
  width: 100%;
}

.cardContentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cartTop {
  display: flex;
  flex-direction: row;
}

.cardTitle {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cardDescriptionText {
  @include text;

  cursor: pointer;
}

.cardTitleText {
  @include textBold;
}

.alreadyLoginTextContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.alreadyLoginText {
  width: 100%;
  display: block;

  > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 3rem);
  }
}

.logoutText {
  @include text;

  display: inline;
  cursor: pointer;
  text-decoration: underline;
}
