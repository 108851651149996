@import '@assets/scss/v2/main';

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3.2rem;
  color: $black;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-bottom: 2.4rem;

  @include respond(m) {
    padding: 0 0 2.4rem 2.4rem;
  }

  .plusSign {
    @include textExtraLarge;

    user-select: none;
  }
}

.headerTitle {
  @include textExtraLargeBold;
}

.rowContainer {
  border-top: 2px solid #000;
}
