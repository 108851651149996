@import '@assets/scss/shared/variables';
@import '@assets/scss/v2/main';

.navigation {
    display: none;
    gap: 2rem;

    & a {
        color: $blue-dark;
        font-weight: 700;
    }

    @include respond(xl) {
        display: flex;
        align-items: center;
    }
}