@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 100%;
}

.title {
  @include textLargeBold;

  color: $black;
  margin-bottom: 1.2rem;
}

.description {
  @include text;

  color: $black;
}
