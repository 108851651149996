@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;

  @include respond(xl) {
    padding: 2rem 0rem;
  }
}

.titleWrapper {
  margin-bottom: 1.5rem;
}

.title {
  line-height: 2.64rem;
  font-size: 2.2rem;
  @include font-weight-bold;
}

.textarea {
  border: 0.2rem solid $silver;
  border-radius: 0.4rem;
  width: 100%;
  height: 17.2rem;
  padding: 0.5rem;

  @include respond(xxl) {
    width: calc(100% - 1.5rem);
  }

  @include respond(xxl) {
    height: 24rem;
  }
}

.invalid {
  border-color: $error;
}

.uploadSection {
  display: flex;
  align-items: center;
}

.uploadWrapper {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.iconWrapper {
  margin-top: 1rem;
  margin-left: 3rem;
}
.uploadPrompt {
  margin: 1rem 0.5rem;
  text-decoration: underline;
}

.noDisplay {
  display: none;
}

.visibilityHidden {
  visibility: hidden;
}

.uploadMessage {
  align-self: stretch;
  text-align: center;
  @include text-sm;
  @include line-height-thight;
  @include font-weight-bold;
  height: 1.7rem;
}

.errorMessage {
  @include textMedium;

  display: flex;
  flex-direction: row;
  margin: 0.4rem 0 0 1.6rem;
  color: $error;
}

.rectangleIcon {
  margin-right: 0.4rem;
}

.attachment {
  padding-left: 2rem;
  margin: 1rem 0;
  align-items: center;
}

.attachmentName {
  @include font-weight-bold;
}
