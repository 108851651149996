@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  align-items: left;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 100%;
}

.paddingContainer {
  padding: 0 2.4rem 2.4rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-top: 1.6rem;
}
.productCardHeader {
  display: flex;
}

.summaryContainer {
  margin-top: auto;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.imageContainer {
  display: flex;
  padding: 1.6rem 0;
  .image {
    background: transparent;
    margin: 0 auto;
  }

  .imageClickable {
    cursor: pointer;
  }
}

.widthButton {
  width: 100%;

  button {
    width: 100%;
  }
}

.deliveryContainer {
  @include textDoubleExtraSmall;
  margin-left: auto;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.topBar {
  border-radius: 4px 4px 0px 0px;
  height: 2.4rem;
  display: flex;
  justify-content: center;
}

.informationBox {
  align-self: flex-end;
}
