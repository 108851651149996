@import '@assets/scss/v2/main';

@mixin container {
  display: flex;
  border-radius: 2rem;
}

@mixin flexDirection {
  flex-direction: column;
  height: 57rem;
  width: 100%;
}

@mixin titleContainer {
  @include textQuadrupleExtraLargeMediumBold;
  padding: 4rem;
  padding-bottom: 0rem;
}

@mixin contentContainer {
  display: flex;
  flex-direction: column;
  margin: auto 0px 0px 0px;
  padding: 4rem;
  padding-top: 2rem;
}

@mixin labelContainer {
  margin-bottom: 1rem;
}

@mixin textContainer {
  @include textExtraLargeMediumBold;
  color: white;
  margin-bottom: 2.4rem;
}

@mixin image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  max-height: 32rem;
  img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
  }
}

.wideContainer {
  .container {
    @include container;
  }

  .flexDirection {
    @include flexDirection;
    @include respond(l) {
      flex-direction: row-reverse;
      width: 100%;
      height: 40rem;
    }
  }

  .titleContainer {
    @include titleContainer;
  }

  .contentContainer {
    @include contentContainer;
    @include respond(l) {
      height: auto;
      margin: 0px auto 0px 0px;
      padding: 3.2rem 4rem;
    }
  }

  .labelContainer {
    @include labelContainer;
    @include respond(l) {
      margin-bottom: auto;
    }
  }

  .textContainer {
    @include textContainer;
  }

  .image {
    @include image;
    overflow: hidden;
    height: 100%;
    @include respond(l) {
      max-height: 100%;
      border-top-left-radius: 0rem;
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }
  }

  .bottomContentContainer {
    @include respond(l) {
      margin-top: auto;
    }
  }
}

.normalContainer {
  .container {
    @include container;
  }

  .flexDirection {
    @include flexDirection;
    @include respond(l) {
      height: 60rem;
    }
  }

  .titleContainer {
    @include titleContainer;
  }

  .contentContainer {
    @include contentContainer;
  }

  .labelContainer {
    @include labelContainer;
  }

  .textContainer {
    @include textContainer;
  }

  .image {
    @include image;
    @include respond(l) {
      max-height: 34.5rem;
    }
  }
}

.contentContainerBackgroundBlueBright {
  background-color: $blue-bright;

  .textContainer,
  .titleContainer {
    color: $white;
  }
}

.contentContainerBackgroundBlueDark {
  background-color: $blue-dark;

  .textContainer,
  .titleContainer {
    color: $white;
  }
}

.contentContainerBackgroundBlueLight {
  background-color: $blue-light;

  .textContainer,
  .titleContainer {
    color: $black;
  }
}

.contentContainerBackgroundBrandYellow {
  background-color: $brand-yellow;

  .textContainer,
  .titleContainer {
    color: $black;
  }
}

.contentContainerBackgroundBrandPink {
  background-color: $brand-pink;

  .textContainer,
  .titleContainer {
    color: $black;
  }
}

.contentContainerBackgroundBrandGreen {
  background-color: $brand-green;

  .textContainer,
  .titleContainer {
    color: $black;
  }
}

.arrowContainer {
  svg {
    width: 2.1rem;
    height: 2rem;
  }
}

.arrowContainer {
  svg {
    width: 2.1rem;
    height: 2rem;
  }
}

.hide {
  display: none;
}
