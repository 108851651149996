$blue-dark: #141b4a;
$blue-bright: #005dff;
$blue-light: #aad6ff;
$black: #15212e;
$pink: #ff6f96;
$yellow: #ffbe00;
$blue-light: #aad6ff;
$green-bright: #43cb16;
$green-fresh: #8ad861;
$color-secondary-grey: #edf1f7;
$color-tertiary-grey: #b6bbcc;
$grey-eb: #ebebeb;
$grey-light: #f7f7f7;
$silver-light: #D9D9D9;
$white: #ffffff;
$max-content-width: 1440px;

// state colors
$error: #e60000;
$success: #17850f;
$warning: #f1c40f;
$disabled: #b6bbcc;

$badge-green: #09a14d;
$badge-orange: #ff8700;
$badge-red: #eb0505;

$tranistion-design-color-replace-blue-bright: #0e3849;

$font-sen: var(--sen);

$font-quicksand: var(--quicksand);

$spacing-2xs: 0.2rem;
$spacing-xs: 0.4rem;
$spacing-s: 0.8rem;
$spacing-m: 1.6rem;
$spacing-l: 2.4rem;
$spacing-xl: 3.2rem;
$spacing-2xl: 4rem;
$spacing-3xl: 4.8rem;
$spacing-4xl: 6.4rem;
$spacing-5xl: 8.8rem;
$spacing-6xl: 12.8rem;
$spacing-7xl: 25.6rem;

$spacing-content-block-top: 5.6rem;
$spacing-content-block-bottom-s: 7.2rem;
$spacing-content-block-bottom: 8rem;

$xs-col-gap: 1.2rem;
$sm-col-gap: 1.6rem;
$md-col-gap: 1.6rem;
$lg-col-gap: 1.6rem;

$header-height-mobile: 64px;
$simple-header-height-mobile: 110px;
$header-height-desktop: 142px;
$simple-header-height-desktop: 110px;

$max-content-width: 1440px;

$modal-header-font-size: 2.4rem;
