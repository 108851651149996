@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  width: 100%;

  @include respond(m) {
    margin-right: 1.6rem;
  }

  @include respond(xl) {
    padding: 0;
  }
  
  @include respond(xxl) {
    margin-right: 0;
  }
}

.title {
  @include text-2xl;
  @include line-height-normal;
  @include font-weight-medium;
  margin-bottom: 1.6rem;

  @include respond(m) {
    @include text-5xl;
    @include line-height-thight;
  }
}

.questionsWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.6rem;

  @include respond(xxl) {
    flex-direction: row;
  }
}

.question {
  width: 100%;

  @include respond(xxl) {
    min-width: calc(50% - 1.6rem);
    max-width: calc(50% - 1.6rem);
  }
}

.errorMessage {
  @include textExtraSmall;
  margin-top: 1.2rem;
  margin-left: 0.5rem;
  color: $error;
}
