@import '@assets/scss/v2/main';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.content {
  width: 100%;
}

.radioContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;
  width: 100%;

  @include respond(l) {
    flex-direction: row;
  }
}

.radioLabel {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 4.7rem;
  justify-content: center;
}

.datePickerContainer {
  height: 4rem;
}

.sectionContainer {
  margin-top: 2.4rem;
  margin-bottom: 0.8rem;
}

.errorMessage {
  @include textExtraSmall;

  margin-top: 1.2rem;
  color: $error;
  margin-left: 0.5rem;
}
