@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  z-index: 4;
  padding: 1.6rem 0;
  gap: 1.6rem;
}

.step {
  display: flex;
}

.title {
  @include textMediumBold;
}

.incrementContainer {
  width: 2.4rem;
  height: 2.4rem;
  background-color: $blue-bright;
  display: flex;
  border-radius: 50%;
  margin-right: 0.8rem;
}

.increment {
  text-align: center;
  width: 2.4rem;
  color: white !important;
  justify-content: center;
  margin: auto;

  @include textMediumBold;
}
