@import '@assets/scss/v2/main';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.widthButton {
  width: 100%;

  button {
    width: 100%;
    color: $black;
    svg {
      path {
        stroke: $black;
      }
    }
  }
}

.inputTitle {
  @include textBold;
}

.formContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  margin-top: 1.2rem;
  border-radius: 2px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}

.form {
  width: 100%;

  > * {
    width: 100%;
    margin: auto;
  }
}

.checkboxContainer {
  padding: 1.2rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.6rem;
  align-items: top;
  width: 100%;
}

.nameInputContainer {
  margin-top: 1.2rem;
}

.sendButton {
  > button {
    width: 100%;
  }
}

.notificationContainer {
  padding-top: 1.8rem;
}
