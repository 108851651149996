@import '@assets/scss/v2/main';

$checkboxSize: 2rem;

.texInputContainer {
  display: flex;
  flex-direction: column;
  color: $black;
  width: 100%;
}

.labelText {
  @include textBold;

  color: $black;
  margin-bottom: 0.4rem;
}

.errorMessage {
  @include textMedium;

  display: flex;
  flex-direction: row;
  margin: 0.4rem 0 0 1.6rem;
  color: $error;
}

.rectangleIcon {
  margin-right: 0.4rem;
}

.textInput {
  margin-bottom: 0.4rem;
}

.hintText {
  display: flex;
  flex-direction: row;
  margin-top: 0.6rem;
}

.dropdownContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dropdown {
  appearance: none;
  background-position: right 1.4rem center;
  background-color: $white;

  width: 100%;
  border: 2px solid $silver;
  border-radius: 4px;
  padding: 1.2rem 4rem 1.2rem 1.6rem;

  cursor: pointer;

  &:focus {
    outline: none;
    border-color: $grey-generic;
  }

  &:disabled {
    outline: none;
    background: none, $grey-eb !important;
  }
}

.dropdownContainer.invalid {
  outline: none;

  select {
    border: 2px solid $error;
  }
}

.dropdownIcon {
  position: absolute;
  top: 4.4rem;
  right: 1.4rem;
}
