@import '@assets/scss/v2/main'; 

.container {
  background-color: $blue-solitude;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.formContent {
  padding-top: 7.2rem;

  @media (min-width: 640px) {
    max-width: 640px;
  }

  @include respond(xs) {
    margin: 1.6rem 1.6rem 0;
  }

  @include respond(s) {
    margin: 2.4rem 2.4rem 0;
  }

  @include respond(m) {
    margin: 3.2rem 3.2rem 0;
  }
}

.cancellationPickUpDateStep {
  & [class*='TitleBlock_title'] {
    & + [class*='RichText_container'] p:nth-child(2) {
      line-height: 2.4rem;
      font-size: 2.4rem;
      font-family: 'Quicksand-Bold';
      color: $blue-bright;
    }
  }

  & [class*='TitleWithText_container'] {
    padding: 0;
  }
}

.cancellationSummary {
  & [class*='TitleWithText_container'] {
    padding: 0;

    & h2 {
      font-family: 'Quicksand-Bold';
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 1.2;

      @media (min-width: 768px) {
        font-size: 2.8rem;
      }
    }
  }

  & [class*='RichText_li'] {
    & p {
      margin-left: 1rem;
    }
  }
}

.cancellationConfirmation {
  & [class*='TitleWithText_container'] {
    padding: 0;

    & h2,
    & div {
      width: 100%;
    }

    & h2 {
      font-family: 'Quicksand-Bold';
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 1.2;

      @media (min-width: 768px) {
        font-size: 2.8rem;
      }
    }
  }
}
