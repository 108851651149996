@import '@assets/scss/v2/main';

.price {
  margin-left: auto;
  text-align: right;
  @include text-sm;
  @include textMediumBold;
  display: flex;
  flex-direction: column;
  line-height: 1.2;

  @include respond(m) {
    @include text-base;
  }
}

.grayedOut {
  opacity: 0.7;
}

.referencePrice {
  line-height: 1.2;
  text-decoration: line-through;
  margin-bottom: 0.2rem;
}

.newPrice {
  color: $success;
}
