@import '@assets/scss/v2/main';

.container {
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.4rem;
  max-height: 2.4rem;
  border-radius: 80px;
  padding: 0.4rem 1.2rem;

  .dot {
    height: 0.7rem;
    width: 0.7rem;
    margin-right: 0.8rem;
    border-radius: 100px;
  }
}

.text {
  @include textExtraSmall;
  font-weight: 700;
  line-height: 100%;
}
