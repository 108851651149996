@import '@assets/scss/v2/main';

.container {
  width: 100%;
  margin-top: 0.4rem;
}

.content {
  display: flex;
  align-items: center;
  background-color: $white-smoke;
  margin-top: 0.4rem;
  padding: 1.2rem;
  width: 100%;
  @include textBold;
  border-radius: 4px;
  flex-direction: row;

  &:first-of-type {
    margin-top: 0;
  }
}
