@import '@assets/scss/v2/main';

.loaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;
}

.formContainer {
    width: 100%;
}

.marginBottom {
    margin-bottom: $spacing-l;
}