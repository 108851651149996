@import '@assets/scss/v2/main';

.infoBarContainer {
  height: 3rem;
  background-color: $blue-bright;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoBarLink {
  padding-left: 0.8rem;
  @include font-sans-bold;
  @include font-weight-normal;
  cursor: pointer;
  text-decoration: underline;
}
