@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
  width: 100%;
}

.separator {
  margin-top: 2.4rem;
}
