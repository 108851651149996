@import '@assets/scss/v2/main';

.container {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  width: 100%;
  max-width: 116rem;

  @include respond(m) {
    flex-direction: row;
    justify-content: space-around;
  }
}

.content {
  justify-content: left;
  @include respond(m) {
    max-width: 53rem;
  }
}

.image {
  display: flex;
  max-width: 44rem;
}
