@import '@assets/scss/v2/main';

.container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.form {
  max-width: 80rem;
  width: 100%;
}

.summaryDescription {
  padding: 2rem;
  @include textBold;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}
