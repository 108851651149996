@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 100%;
}

.faqArticlesContainer {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding-bottom: 3.2rem;
}

.faqArticle {
  display: flex;
  flex-direction: column;

  &:last-child {
    padding-bottom: 2.4rem;

    @include respond(xs) {
      padding-bottom: 2.4rem;
    }

    @include respond(s) {
      padding-bottom: 2.4rem;
    }

    @include respond(m) {
      padding-bottom: 3.2rem;
    }
  }
}

.faqArticleLabelContainer {
  display: flex;
  flex-direction: row;
}

.icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.faqArticleLabel {
  @include textLargeBold;

  text-transform: capitalize;
  display: flex;
  color: $black;
}

.plusSign {
  @include textExtraLarge;

  color: $black;
  user-select: none;
  line-height: 1.3rem;
}

.faqArticleHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5rem;
  border-bottom: 2px solid $black;
  padding: 1.6rem 0;
  margin: 0 1.6rem;

  @include respond(m) {
    margin: 0 4rem;
  }
}

.faqArticleGroupContainer {
  & > * {
    box-shadow: none;
  }
}

.nonElevatedLabel {
  margin: 0 1.6rem;
}
