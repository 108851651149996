@import '@assets/scss/v2/main';

.container {
  display: flex;
  background-color: $white;
  justify-content: left;
  align-items: left;
  width: 100%;
}

.logo {
  width: 16.7rem;
  height: 6.1rem;

  @include respond(l) {
    width: 26.7rem;
  }
}

.headerContainer {
  display: flex;
  margin: 1.4rem 2rem;
  color: $black;
  height: 6.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include respond(l) {
    margin: 2.4rem 5.2rem;
  }
}

.link {
  display: flex;
}

.backButton {
  display: none;

  @include respond(l) {
    display: flex;
    margin: auto;
    margin-left: 4rem;
    height: 4rem;
  }
}

.mobileBackButton {
  margin: auto 0;
  & button {
    border-radius: 0.3rem !important;
  }

  @include respond(l) {
    display: none;
  }
}

.desktopText {
  display: none;

  @include respond(l) {
    display: flex;
  }
}

.logout {
  @include textBold;
  display: flex;
  padding-right: 0.8rem;
  align-items: center;
  cursor: pointer;

  @include respond(l) {
    padding-right: 2.6rem;
  }

  span {
    display: none;

    @include respond(l) {
      display: flex;
    }
  }

  svg {
    margin-left: 1.6rem;
  }
}
