@import '@assets/scss/v2/main';

.theme {
  height: 100%;
}

.themeBackgroundWhite {
  background-color: $white;
}

.themeBackgroundBlueBright {
  background-color: $blue-bright;
}

.themeBackgroundBlueDark {
  background-color: $blue-dark;
}

.themeBackgroundBlueLight {
  background-color: $blue-light;
}

.themeBackgroundBrandYellow {
  background-color: $brand-yellow;
}

.themeBackgroundBrandPink {
  background-color: $brand-pink;
}

.themeBackgroundBrandGreen {
  background-color: $brand-green;
}

.themeBackgroundBlueMuted {
  background-color: $blue-solitude;
}
