@import '@assets/scss/shared/variables';

.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($black, 0.2);
}
