@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  margin: 1.6rem 0 2.4rem;
  width: 100%;
  gap: 1.6rem;
}

.monthlyFeeContainer {
  display: flex;
  justify-content: space-between;
  height: 4rem;
  align-items: center;
  border-top: 1px solid $disabled;
}

.monthlyFeeContainerText,
.monthlyFeeContainerValue {
  @include textMediumBold;
}
