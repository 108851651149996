@import '@assets/scss/v2/main';

.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: $white-smoke;
    padding: 0.4rem 1.2rem;
    justify-content: space-between;
    align-items: center;
    min-height: 4.3rem;
}

.left {
    display: flex;
    flex-direction: row;
    gap: 0.4rem;
    @include textSmall;
    @include font-weight-bold
}

.right {
    @include textSmall;
    @include font-weight-bold
}