@import '@assets/scss/v2/main';

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}

.formContainer {
  width: 100%;
}

.instructionText {
  @include textBold;
  margin-bottom: $spacing-l;
}

.radioLabel {
  display: flex;
  align-items: center;
}

.radioLabelTextContainer {
  margin-left: 0.85rem;
  display: flex;
  flex-direction: column;
}

.radioLabelLightText {
  @include textSmall;
  @include line-height-loose;
}

.radioChildrenSeperator {
  width: calc(100% + 2.4rem);
  margin-top: 1.6rem;
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  margin-bottom: 1rem;
}

.creditCardRow {
  display: flex;
  justify-content: space-between;
}

.creditCardColumn {
  width: 46%;
}

.recurringConsentCheckbox {
  width: 100%;
}

.inputContainer {
  margin-bottom: 1.6rem;
}

.radioLabelNameText {
  @include textBold;
}

.changeAlreadyDefinedPaymentContainer {
  margin-bottom: 2rem;
  width: 100%;
}

