@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

$verticalMargin: 2.4rem;
$horizontalMargin: 0;
$bottomMargin: 0;

.compactLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 128rem;
  justify-content: center;

  @include respond(xs) {
    margin: $verticalMargin $horizontalMargin $bottomMargin;
  }

  @include respond(s) {
    margin: $verticalMargin $horizontalMargin $bottomMargin;
  }

  @include respond(m) {
    margin: $verticalMargin $horizontalMargin $bottomMargin;
  }

  @include respond(l) {
    margin: $verticalMargin $horizontalMargin $bottomMargin;
  }

  @include respond(xl) {
    margin: $verticalMargin $horizontalMargin $bottomMargin;
  }

  @include respond(xxl) {
    margin: $verticalMargin $horizontalMargin $bottomMargin;
  }
}
