@import '@assets/scss/v2/main';

.form {
  width: 100%;

  > * {
    width: 100%;
    margin: auto;
  }
}

.additionalServices {
  display: inline-flex;
  justify-content: space-between;
  height: 4.5rem;
  align-items: center;
}

.additionalServicesText {
  @include textMediumBold;
}

.link {
  @include textLink;

  cursor: pointer;
}

.labelLink {
  display: inline;
  color: $blue-bright;
  cursor: pointer;
}

.additionalServiceOption {
  cursor: auto;
}

.additionalService {
  display: flex;
  flex-direction: row;
  border: 1px solid $disabled;
  box-sizing: border-box;
  border-radius: 0.4rem;
  padding: 0.8rem 1.2rem;
  margin-bottom: 0.8rem;
  height: 6rem;
  align-items: center;
  background-color: $white;
  cursor: pointer;

  &:hover {
    border: 3px solid $blue-dark;
    padding: 0.8rem 1rem;
    opacity: 0.8;
  }
}

.additionalServiceSelected {
  border: 3px solid $blue-dark;
  padding: 0.8rem 1rem;
}

.additionalServiceLabel {
  @include textMediumBold;

  margin: 0 0.08rem;
}

.additionalServiceOption {
  display: flex;
  justify-content: space-between;
}

.additionalServicesWrapper {
  display: flex;
  flex-direction: column;
}
