@import '@assets/scss/v2/main';

.container {
    display: flex;
    flex-direction: column;
}

.s8 {
    gap: 0.8rem;
}

.s16 {
    gap: 1.6rem;
}

.m0 {
    margin-top: 0;
    margin-bottom: 0;
}

.m8 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

.m16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
}

.bgTransparent {
    background-color: transparent;
}

.bgWhite {
    background-color: $white;
}

.p16 {
    padding: 1.6rem;
}

.p24med {
    @include respond (m) {
        padding: 2.4rem;
    }
}

.shadow {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.20);
}