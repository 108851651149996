@import '@assets/scss/v2/main';

.labelText {
  @include textBold;

  color: $black;
  margin-bottom: 0.4rem;
}

.errorMessage {
  @include textMedium;

  display: flex;
  flex-direction: row;
  margin: 0.4rem 0 0 1.6rem;
  color: $error;
}

.rectangleIcon {
  margin-right: 0.4rem;
}

.dropdownsContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
}

.dropdownContainer {
  position: inherit;
  width: 100%;
}

.dropdown {
  display: block;
  appearance: none;
  background-position: right 1.4rem center;
  width: 100%;
  border: 2px solid $silver;
  border-radius: 4px;
  padding: 1.2rem 4rem 1.2rem 1.6rem;

  cursor: pointer;

  &:focus {
    outline: none;
    border-color: $grey-generic;
  }

  &:disabled {
    outline: none;
    background: none, $grey-eb !important;
  }
}

.dropdownContainer.invalid {
  outline: none;

  select {
    border: 2px solid $error;
  }
}

.dropdownIcon {
  position: absolute;
  top: 4.4rem;
  right: 1.4rem;
}
