@import '@assets/scss/v2/main';

$checkboxSize: 2rem;

.texInputContainer {
  display: flex;
  flex-direction: column;
  color: $black;
  width: 100%;
}

.labelText {
  @include textBold;

  color: $black;
  margin-bottom: 0.4rem;
}

.errorMessage {
  @include textMedium;

  display: flex;
  flex-direction: row;
  margin: 0.4rem 0 0 1.6rem;
  color: $error;
}

.rectangleIcon {
  margin-right: 0.4rem;
}

.textInput {
  margin-bottom: 0.4rem;
}

.hintText {
  @include textMedium;

  display: flex;
  flex-direction: row;
  margin-top: 0.6rem;
}

.textInputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    border: 2px solid $silver;
    border-radius: 4px;
    padding: 1.2rem 4rem 1.2rem 1.6rem;

    &:focus {
      outline: none;
      border-color: $grey-generic;
    }

    &:disabled {
      outline: none;
      background: none, $grey-eb !important;
    }
  }
}

.inputContainer {
  position: relative;
  display: flex;
}

.passwordInput {
  padding-right: 7.5rem !important;
}

.searchInput {
  height: 5.4rem !important;
  padding-left: 4.5rem !important;
  padding-right: 1.6rem !important;
  border-radius: 0 !important;
  border: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  outline: none;
}

.lengthCounter {
  @include textBold;

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0.5rem 0.5rem 0.9rem 0;
  padding: 0 1rem;
  width: 6.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-color: $grey-eb;
  border-radius: 4px;
  border: 0;
}

.hideButton {
  @include textBold;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0.5rem 0.5rem 0.9rem 0;
  padding: 0 1rem;
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-color: $grey-eb;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
}

.validationIcon {
  position: absolute;
  top: 50%;
  right: 1.4rem;
  transform: translateY(-50%);
}

.searchIcon {
  position: absolute;
  top: 50%;
  left: 1.1rem;
  transform: translateY(-50%);
}

.loader {
  position: absolute;
  top: 50%;
  right: 1.4rem;
  transform: translateY(-50%);
  & div {
    border-width: 4px;
  }
}
