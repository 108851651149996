@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 100%;
}

.resultsContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
  flex-wrap: nowrap;
  gap: 2.4rem 0;

  @include respond(xl) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2.4rem 5.4rem;
  }
}

.articlesFound {
  @include text;

  color: $grey-generic;
  margin-bottom: 2.4rem;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}

.buttonContainer {
  margin-bottom: 2.8rem;
}
