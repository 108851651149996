@import '@assets/scss/v2/main';

.notificationContainer {
  padding-top: 1.8rem;
}

.notificationButton {
  color: $black;
  background-color: transparent;
  box-shadow: none;
  border: 1px solid $blue-dark;
  border-radius: 4px;
  padding: 0.8rem, 1.6rem;
  margin-top: 0.8rem;
}

.arrow path {
  stroke: $black;
}
