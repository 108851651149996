@import '@assets/scss/v2/main';

.link {
  cursor: pointer;
  flex: 1 1;
  max-width: 40rem;
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  color: $black;
  flex-wrap: wrap;
  gap: 0;
  padding-left: 0;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;

  &:last-child {
    border-bottom: none;
  }

  @include respond(m) {
    gap: 2.4rem;
    padding-left: 2.4rem;
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: row;
  }
}

.cell {
  @include textSmall;

  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0;

  @include respond(m) {
    @include text;

    padding: 1.6rem 0;
  }
}

.value {
  flex: 1;
  padding-top: 0.4rem;

  @include respond(m) {
    padding-top: 1.6rem;
  }
}

.header {
  @include textExtraSmallBold;

  width: 100%;
  max-width: 40rem;
  display: flex;
  align-items: center;

  @include respond(m) {
    @include textLargeBold;

    width: unset;
    flex: 1;
  }

  svg {
    margin-left: 0.5rem;
    width: 1.2rem;
  }
}
