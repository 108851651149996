@import '@assets/scss/v2/main';

.container {
  padding: 2.4rem 1rem 0 1rem;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-track {
    background: $white-smoke;
    margin: 0.5rem;
    border-radius: 3rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3rem;
    border: 0.3rem solid $white-smoke;
  }

  @include respond(m) {
    padding: 4rem 8rem;
    width: 100%;
  }
}

.narrow {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.table {
  border-collapse: collapse;
  border: 0.1rem solid;
  overflow-x: scroll;
  width: 100%;
}

.tableHeader {
  padding: 0.8rem;
  text-align: left;
  border: 0.1rem solid;
  @include text-sm;
  @include font-weight-normal;
  white-space: nowrap;
}

.tableCell {
  padding: 0.8rem;
  border: 0.1rem solid;
  vertical-align: top;
  @include text-sm;
}

.tableScrollGrey {
  &::-webkit-scrollbar-thumb {
    background-color: $grey-alabaster;
    border: 0.3rem solid $blue-light;
  }

  &::-webkit-scrollbar-track {
    background: $blue-light;
  }
}

.tableScrollBlueBright {
  &::-webkit-scrollbar-thumb {
    background-color: $blue-bright;
    border: 0.3rem solid $grey-alabaster;
  }
}

.tableScrollBlueDark {
  &::-webkit-scrollbar-thumb {
    background-color: $blue-dark;
  }
}

.tableScrollBlueLight {
  &::-webkit-scrollbar-thumb {
    background-color: $blue-light;
  }
}

.tableScrollBrandYellow {
  &::-webkit-scrollbar-thumb {
    background-color: $brand-yellow;
  }
}

.tableScrollBrandPink {
  &::-webkit-scrollbar-thumb {
    background-color: $brand-pink;
  }
}

.tableScrollBrandGreen {
  &::-webkit-scrollbar-thumb {
    background-color: $brand-green;
  }
}

.tableHeader:nth-child(1),
.tableCell:nth-child(1) {
  @include font-weight-bold;
}

.tableHeader:nth-child(3),
.tableCell:nth-child(3) {
  min-width: 30rem;

  @include respond(m) {
    width: 40rem;
  }
}
