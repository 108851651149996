@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  width: 28.7rem;
  user-select: none;
}

.image {
  pointer-events: none;

  img {
    height: 29.1rem;
    height: 28.7rem;
    min-height: 29.5rem;
  }
}

.itemContainer {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
}

.name {
  @include textExtraLargeBold;
}

.title {
  @include text;
}
