@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
}

.summaryLine {
  border: none;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  height: 2px;
  background-color: $black;
}

.rowLine {
  border: none;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  height: 1px;
  background-color: $black;
}

.normalPriceAboveDiscount {
  text-decoration: line-through;
}

.rowLeftColumn {
  margin-right: auto;
  @include textLargeBold;
}

.rowRightColumn {
  margin-left: 2rem;
  text-wrap: nowrap;
  @include textExtraLargeBold;
}

.discountedPrice {
  display: block;
  color: $success;
  margin: 1rem auto 1rem 0;
}

.rowLeftColumnDiscountMessage {
  @include textLarge;
}
