@import '@assets/scss/v2/main';

.container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.checkoutCard {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin: 2.4rem 0;

  @include respond(l) {
    flex-direction: row;
    max-width: 100%;
  }
}

.checkoutCard:first-child {
  margin-top: 0;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 14.2rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  max-width: 100%;

  @include respond(l) {
    justify-content: flex-end;
    height: auto;
    max-width: 21rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
  }
}

.image {
  display: flex;
  justify-content: center;
  margin: 0;
  max-height: 100%;

  @include respond(l) {
    display: flex;
    max-width: 28rem;
    align-self: flex-start;
  }

  > img {
    object-fit: contain;
  }
}

.content {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 2.4rem 1.6rem 1.6rem;
  width: 100%;
  color: $black;
  border-top-right-radius: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

  @include respond(l) {
    padding: 2.4rem 1.6rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: none;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.2rem;
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.infoContainer {
  display: block;
}

.duration {
  @include textExtraSmallBold;

  display: flex;
  flex-direction: row;
  background: $blue-solitude;
  border-radius: 50px;
  width: max-content;
  padding: 0.4rem 1.2rem;
  margin: 0.5rem 0;
}

.labelsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.6rem;

  > * {
    margin-right: 0.8rem;
  }
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  background: $grey-alabaster;
  border-radius: 4px;
  padding: 1.2rem;
  margin-bottom: 0.8rem;
}

.checkboxContainer:last-child {
  margin-bottom: 0;
}

.checkboxDescription {
  display: flex;
  flex-direction: column;
  margin-top: 2.1rem;
}

.titleText {
  @include textDoubleExtraLargeBold;
}

.descriptionText {
  @include text;

  margin-bottom: 1.6rem;
}
