@import '@assets/scss/shared/variables';
@import '@assets/scss/v2/main';

.hiddenDropdown {
  display: none;
}

.visibleDropdown {
  display: flex;
}

.dropdownContent {
  position: absolute;
  flex-direction: column;
  background-color: $white;
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
  min-width: 25rem;
  z-index: 99;
}