@import '@assets/scss/v2/main';

.additionalItemsContainer {
  margin-top: 1.25rem;
  padding: 0rem 1.2rem;
}

.additionalItemHeader {
  display: flex;
  gap: 0.5rem;
  margin: 1rem 0;

  @include line-height-thight;

  .link {
    color: $blue-bright;
    cursor: pointer;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.4rem;
  width: 100%;
}
