@import '@assets/scss/v2/main';

.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .datePicker::-webkit-calendar-picker-indicator {
    background: none;
    position: absolute;
    left: 0;
    height: 40px;
    width: 44px;
    cursor: pointer;
  }

  input[type='date'] {
    @include default-font-color;
    @include font-sans;
    @include text-base;
    @include line-height-normal;
    @include uppercase;

    position: relative;
    display: flex;
    cursor: pointer;
    padding-left: 58px;
    border-right: solid 2px $silver;
    border-top: solid 2px $silver;
    border-bottom: solid 2px $silver;
    border-left: 0;
    border-radius: 4px;
    height: 44px;
    width: 100vh;
    max-width: 100%;
    outline: none;
    color: $black;
  }

  input[type='date']::before {
    background: url('../../../assets/icons/new/inputs/calendar.png') no-repeat, #ededed;
    height: 40px;
    width: 44px;
    display: block;
    content: '';
    border-left: solid 2px $silver;
    border-right: solid 2px $silver;
    background-color: #ededed;
    background-position: right 1.1rem center;
    position: absolute;
    left: 0;
  }
}

.errorMessage {
  @include textMedium;

  display: flex;
  flex-direction: row;
  margin: 0.4rem 0 0 1rem;
  color: $error;
}

.rectangleIcon {
  margin-right: 1.6rem;
}
