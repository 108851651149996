.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10rem;
  margin-top: auto;
  margin-bottom: 30rem;
}
.loaderInfoContainer {
  display: flex;
  flex-direction: column;
  min-height: 50rem;
  width: 100%;
}
.container {
  width: 100%;
}

.info {
  margin-bottom: 4rem;
}
