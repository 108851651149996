@import '@assets/scss/v2/main';

.container {
  display: flex;
  flex-direction: column;
  background: $white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 2.4rem 1.6rem;
  color: $black;

  @include respond(xs) {
    padding: 2.4rem 1.6rem;
  }

  @include respond(s) {
    padding: 2.4rem 1.6rem;
  }

  @include respond(m) {
    padding: 3.2rem 4rem;
  }
}

.title {
  @include textDoubleExtraLargeBold;

  color: $blue-bright;
}

.description {
  @include text;

  margin: 1.2rem 0;
}

.scoreInputContainer {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin: 1rem 0;
}

.scoreButton {
  @include textExtraSmallMediumBold;

  flex: 1;
  margin: 0.2rem;
  border: 1px solid $silver;
  background: none;
  box-sizing: border-box;
  height: 5rem;
  cursor: pointer;
}

.scoreDescription {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-right: 0.5rem;
}

.scoreDescriptionText {
  @include textExtraSmallMediumBold;
}
