@import '@assets/scss/v2/main';

.iconText {
    padding: 0.8rem;
    display: flex;
    gap: 0.8rem;
    flex-direction: row;
    justify-content: flex-start;
}

.text {
    @include textButton;
    line-height: 150%;

    @include respond(m) {
        font-size: 1.6rem;
    }
    
}

.icon {
    display: flex;
    align-items: center;
}

